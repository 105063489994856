.fileThumbCard {
    background: #ffffff;
    border: 1px solid #ebecf0;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 20%);
    border-radius: 6px;
    //height: 130px;
  }
  
  .fileName {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    //display: flex;
    //align-items: center;
    margin-left: 50px;
    color: #262626 !important;
  }
  
  .status {
    border-radius: 2px;
    padding: 2px;
    box-sizing: border-box;
  }
  
  .submittedStatusBackground {
    background: #E9FDF8 !important;
  }
  
  .notSubmittedStatusBackground {
    background: #FFCCCC !important;
  }
  
  .submittedGreenColor {
    color: #00CD37 !important;
  }
  
  .notSubmittedRedColor {
    color: #DE350B !important;
  }
  
  .fileImg {
    height: 38px;
    width:38px;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 28px;
    left: 8px;
    //margin: auto;
    //margin-bottom: 5px;
  }
  
  
  .pendingStatusBackground {
    background: #FFFAE5 !important;
  }
  
  .pendingColor {
    color: #E0B400 !important;
  }
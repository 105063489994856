.one_to_one___back {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #FFFFFF;
  padding-inline: 2rem;
}

.main__upload__box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.all__upload__images {
  margin: 1rem 0.5rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  width: 70px;
  height: 70px;
  border: 1px solid #333;
}

.all__upload__images>img {
  width: 100%;
  height: 100%;
}

.all__upload__images button {
  cursor: pointer;
  padding: 0px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  color: white;
  border: none;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  right: -10px;
  top: -10px;
}

.pdfView__preview {
  margin: 0.5rem 10px;
  position: relative;
  padding: 8px;
  width: 243px;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #E8E8EA;
  border-radius: 4px;
  display: flex;
}

.pdfView__preview p {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  color: #262626;
}

.pdfView__preview>button {
  cursor: pointer;
  padding: 0px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  color: white;
  border: none;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  right: -10px;
  top: -10px;
}

.one_to_one___back>p {
  margin-inline-start: 1rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.one_to_one___back>svg {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
}

#employeeTabs {
  width: 95%;
  margin: auto;
}

.directory__profile__container {
  width: 100vw;
  height: calc(100vh - 65px);
  background-color: #FAFBFC;
  letter-spacing: 0.5px;
  overflow: scroll;
  font-style: normal;
  padding-bottom: 15px;
}

.continus___feedback {
  width: 100%;
  /* margin-inline: auto;
  margin-block-start: 1.5rem; */
}

.one__to__one__card {
  display: block;
  padding: 12px;
  width: 100%;
  height: 78px;
  background: #FFFFFF;
  border: 1px solid #EBECF0;
  border-radius: 4px;
  margin-top: 20px;
}

.one__to__one__card__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.one__to__one__icon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  width: 20px;
  height: 20px;
  background: #F6FAFF;
  border-radius: 20px;
  margin-right: 12px;
}

.one__to__one__icon>svg {
  color: #4C8BFF;
  width: 12px;
  height: 12px;
}

.one__to__one__card__top h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #172B4D;
  margin: 0;
  padding: 0;
  word-break: break-all;

}

.one__to__one__card__top h3 span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  color: #FAC800;
}

.face__to__face {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: #FFFFFF;
  border: 1px solid #EBECF0;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #172B4D;
}

.one__icons__bottom {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.one__icons__bottom>p {
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #615E69;
}

.one__icons__bottom>p>span>svg {
  width: 14px;
  height: 14px;
  color: #615E69;
  margin-right: 10px;
}



.one__to__one__reschedule {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  height: 20px !important;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #335AFF !important;
}

.one__to__one__cancel {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  height: 20px !important;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #FF0000 !important;
}

.feedback__tabs {
  background: #F3F3F3;
  border-radius: 4px;
  padding: 0px;
}

.feedback__tabs>button {
  padding: 7px 20px !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #615E69;
  border-radius: 4px !important;
  margin: 2px;
  border: none !important;
}

.feedback__tabs>button:hover {
  background-color: #fff !important;
}



/* Meeting Details Modal */
.meeting__details {
  padding: 30px;
}

.meeting__details__topbox {
  clear: both;
}

.meeting__details__topbox h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #172B4D;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
}

.meeting__details__topbox .meeting-desc {
  word-wrap: break-word;
}

.meeting__details__topbox h3 span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-left: 10px;
}

.meeting__details__topbox p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #A5A3A9;
}

.bottom_create_loaction {
  display: flex;
}

.bottom_create_loaction>p {
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #B3B3B3;
  padding-right: 10px;
  margin-right: 10px;
}

.bottom_create_loaction>p:not(:last-child){
  border-right: 1px solid #B3B3B3;
}

.bottom_create_loaction>p>span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #615E69;
  margin-left: 10px;
}

.date__time__box {
  clear: auto;
  display: flex;
}

.date__time__box>p {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  height: 28px;
  background: #FFFFFF;
  border: 1px solid #EBECF0;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #615E69;
  margin-right: 24px;
}

.date__time__box>p>span>svg {
  color: #615E69;
  width: 14px;
  height: 14px;
  margin-right: 10px;
  float: left;
}

.connected__meeting {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #615E69;
}

.connected__meeting>span>svg {
  color: #615E69;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.connected__meeting span {
  color: #172B4D;
  margin-left: 10px;
}

.meeting__link {
  clear: both;
  margin-top: 30px;
}

.meeting__link h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #172B4D;
  margin: 0px 0px 8px 0px;
  padding: 0px;

}

.meeting__link h4>svg {
  width: 14px;
  height: 14px;
  color: #615E69;
  margin-right: 10px;
}

.meeting__link p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  text-decoration-line: underline;
  color: #335AFF;
}

.remarks__meeting {
  clear: both;
  padding: 12px;
  isolation: isolate;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-top: 30px;
}

.remarks__meeting h4 {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #172B4D;
  margin: 0px 0px 15px 0px;
}

.remarks__meeting h4 span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  height: 20px;
  background: #FFFFFF;
  border: 1px solid #EBECF0;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #172B4D;
}

.remarks__meeting p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #172B4D;
  margin: 0px;
  padding: 0px;
  word-break: break-all;
}

.download__link__meeting {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #4F4F4F;
  display: flex;
  align-items: center;
  padding: 12px;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  border-radius: 4px;
  width: 215px;
  margin: 12px 0px;
}

.download__link__meeting>svg {
  margin: 0px 7px;
  cursor: pointer;
}

.comment__users__meeting {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #172B4D;
  margin-top: 15px;
}

.comment__users__meeting>span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 6px;
  color: #AFAFAF;
}

.reschedule__meeting {
  margin-right: 20px;
  padding: 7.5px 15px;
  width: 112px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #335AFF;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  color: #335AFF;
}

.markas__completed__meeting {
  padding: 7.5px 15px;
  width: 165px;
  height: 36px;
  background: #335AFF !important;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
  white-space: nowrap;
}

.editor__remarks {
  height: auto;
  background: #FFFFFF;
  border: 1px solid #A5A3A9;
  border-radius: 8px;
  margin-top: 30px;
  padding: 4px;
}

.editor__bottom {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.editor__bottom span {
  float: left;
  cursor: pointer;
}

.editor__bottom span>svg {
  opacity: 0.5;
}

.remarks__send {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 80px;
  height: 36px;
  background: #335AFF;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.remarks__send:hover {
  background: #335AFF;
}


.images__structure {
  display: flex;
  flex-flow: wrap;
  column-gap: 20px;
  margin-top: 15px;
}

.images__structure img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border: 1px solid #eee;
  background-color: #f2f2f2;
  margin-bottom: 20px;
}
/*====================================== Sahud khan ========================================*/
@font-face {
    font-family: "Segoe UI Web (West European)";
    src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-light.woff2")
        format("woff2"),
      url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-light.woff")
        format("woff");
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Segoe UI Web (West European)";
    src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semilight.woff2")
        format("woff2"),
      url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semilight.woff")
        format("woff");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Segoe UI Web (West European)";
    src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff2")
        format("woff2"),
      url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff")
        format("woff");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Segoe UI Web (West European)";
    src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff2")
        format("woff2"),
      url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff")
        format("woff");
    font-weight: 600;
    font-style: normal;
  }
  
  
  * {
    font-family: "Segoe UI Web (West European)" !important;
    font-style: normal !important;
  }
  
  
  body {
    font-family: "Segoe UI Web (West European)" !important;
    font-style: normal !important;
    letter-spacing: 0.3px !important;
  }

  .MuiButtonBase-root{
   text-transform: capitalize !important;   
  }

  .element-card {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }

  .actionable_card{
    transition: all 0.3s ease-out;    
  }

  .actionable_card:hover{
    // transform: translateY(-5px) scale(1.005) translateZ(0);
    transform: translateY(-7px); /* Adjust the translateY value for the desired upward movement */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Adjust the shadow values as needed */
    cursor: pointer;
}
  
  $font-Poppins: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  $font-Roboto: "Roboto", sans-serif;
  $admin-table-height: 590/8.15+"vh";
  $employee-table-height: 650/8.15+"vh";
  
  body > iframe[style*='2147483647']{
    display: none;
  }
  
  @mixin overflowAuto() {
    overflow: auto;
  
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #efefef;
    }
  
    &::-webkit-scrollbar {
      width: 0.4rem !important;               /* width of the entire scrollbar */
  
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 0.4rem !important;
      background-color: #bdbdbd;
    }
  }
  
  
  *,body,button,ul,li{
    font-family: 'Segoe UI Web (West European)' !important;
    letter-spacing: 0.3px !important;
  }
  
  .form-control {
    width: 100%;
    height: 40px;
    border: 1px solid #ebecf0;
    border-radius: 6px;
    padding: 10px 15px;
    outline: none;
  }
  
  input {
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    color: #172b4d;
    letter-spacing: 0.01em;
    border: 1px solid rgb(225, 226, 228);
    // height: 35px;
    border-radius: 4px;
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
  }
  
  input::-webkit-calendar-picker-indicator {
    color: #172b4d !important;
    width: 24px;
    height: 24px;
  }
  
  input[type="file"] {
    color: #979797;
  
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
  }
  
  input[type="file"]::-webkit-file-upload-button {
    outline: none;
    width: 104px;
    height: 25px;
    border-radius: 5pc;
    color: #172b4d;
    background-color: #f2f2f2;
    border: 0px;
    border-radius: 3px;
  
    font-weight: 400;
    font-size: 14px;
  }
  
  input[type="file"]::file-selector-button {
    outline: none;
    width: 104px;
    height: 25px;
    color: #172b4d;
    background-color: #f2f2f2;
    border: 0px;
    border-radius: 3px;
  
    font-weight: 400;
    font-size: 14px;
  }
  
  input[type="file"]::-webkit-file-upload-button:hover {
    outline: none;
    width: 104px;
    height: 25px;
    color: #172b4d;
    background-color: #f2f2f2;
    border: 0px;
  
    font-weight: 400;
    font-size: 14px;
    border-radius: 3px;
  }
  
  input[type="file"]::file-selector-button:hover {
    outline: none;
    width: 104px;
    height: 25px;
    color: #172b4d;
    background-color: #f2f2f2;
    border: 0px;
  
    font-weight: 400;
    font-size: 14px;
    border-radius: 3px;
  }
  
  input::placeholder {
    font-weight: 400;
    font-size: 0.825rem;
    opacity: 0.5;
    color: #172b4d;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input:required {
    box-shadow: none;
    outline: none;
  }
  input:required:focus {
    box-shadow: none;
    outline: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    margin: 0;
  }
  input[type="month"] {
    border: 1px solid #ebecf0;
    border-radius: 5px;
    font-size: 14px;
    padding: 3px 6px 3px 14px;
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
  }
  
  input[type="month"]:hover {
    border: 1px solid #ebecf0;
  }
  
  input::-webkit-calendar-picker-indicator {
    color: #172b4d !important;
    width: 24px;
    height: 24px;
  }
  .text-overflow-hide{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  input::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 0.825rem;
    opacity: 0.5;
    color: #172b4d;
  }
  
  input:-ms-input-placeholder {
    font-weight: 400;
    font-size: 0.825rem;
    opacity: 0.5;
    color: #172b4d;
  }
  
  input::-ms-input-placeholder {
    font-weight: 400;
    font-size: 0.825rem;
    opacity: 0.5;
    color: #172b4d;
  }
  
  datalist {
    @include overflowAuto();
    height: 300px;
  }
  
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  
  ::-webkit-scrollbar {
    width: 0.5vh;
    height: 0.5vh;
    background-color: transparent;
  
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #e9e7e7;
    }
  }
  
  .wh-100-auto {
    width: 100%;
    height: auto;
  }
  
  .wh-auto {
    width: auto;
    height: auto;
  }
  
  .b-0 {
    border: 0% !important;
  }
  
  .b-1 {
    border: 0.0625rem solid #bbddbb !important;
  }
  
  .mt-10 {
    margin-top: 10px !important;
  }
  .m-l-t-30 {
    margin-left: 30px !important;
    margin-top: 30px !important;
  }
  
  .leave-rule-popup-title {
    display: flex;
    justify-content: space-between;
    margin: 25px;
  }
  
  .mr-12 {
    margin-right: 1.47vh !important;
  }
  .mr-2 {
    margin-right: 0.47vh !important;
  }
  .mt-20 {
    margin-top: 2.454vh !important;
  }
  .vhd {
    color: #757575 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    list-style: 16px !important;
  }
  .mt-1 {
    margin-top: 1vh !important;
  }
  
  .mr-20 {
    margin-right: 2.454vh !important;
  }
  
  .ml-20 {
    margin-left: 2.454vh !important;
  }
  .ml-30 {
    margin-left: 3.68vh !important;
  }
  .mr-30 {
    margin-right: 3.68vh !important;
  }
  
  .mr-15 {
    margin-right: 1.84vh !important;
  }
  
  .mr-55 {
    margin-right: 6.748vh !important;
  }
  .pt-0 {
    padding-top: 0px !important;
  }
  .ml-5 {
    margin-left: 0.226vh !important;
  }
  .ml-10 {
    margin-left: 1.226vh !important;
  }
  
  .mt-30 {
    margin-top: 3.68vh !important;
  }
  
  .mt-15 {
    margin-top: 1.84vh !important;
  }
  .mb-30 {
    margin-bottom: 3.68vh !important;
  }
  .mb-32 {
    margin-bottom: 3.926vh !important;
  }
  .pb-10 {
    padding-bottom: 1.68vh !important;
  }
  
  .mb-20 {
    margin-bottom: 20px !important;
  }
  
  .mv-15 {
    margin-top: 1.84vh !important;
    margin-bottom: 1.84vh !important;
  }
  
  .p-10 {
    padding: 10vh 10vw !important;
  }
  .p-5 {
    padding: 5vh 5vw !important;
  }
  .p-8 {
    padding: 8vh 8vw !important;
  }
  .p-16 {
    padding: 1rem !important;
  }
  .p-12 {
    padding: 12px !important;
  }
  .pt-22 {
    padding-top: 22px !important;
  }
  
  .m-0 {
    margin: 0% !important;
  }
  .m-20 {
    margin: 20px !important;
  }
  .p-20 {
    padding: 20px !important;
  }
  .p-0 {
    padding: 0% !important;
  }
  .mb-40 {
    margin-bottom: 40px !important;
  }
  .mp-0 {
    margin: 0% !important;
    padding: 0% !important;
  }
  
  .w-100 {
    width: 100% !important;
  }
  .w-95{
    width: 95% !important;
  }
  .borderNone > fieldset {
    border: none !important;
  }
  .w-90 {
    width: 90% !important;
    padding-left: 5%;
    padding-top: 3%;
  }
  .w-48 {
    width: 48% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  
  .w-70 {
    width: 70% !important;
  }
  .w-46 {
    width: 46% !important;
  }
  .w-30 {
    width: 30% !important;
  }
  
  .w-25 {
    width: 25% !important;
  }
  
  .w-75 {
    width: 75% !important;
  }
  .w-15 {
    width: 15% !important;
  }
  .w-85 {
    width: 85% !important;
  }
  .w-auto {
    width: auto !important;
  }
  
  .h-100 {
    height: 100% !important;
  }
  
  .h-inherit {
    height: inherit;
  }
  
  .h-100-vh {
    height: 100vh;
  }
  
  .h-65-vh{
    height: 65vh !important;
  }
  
  .wh-100 {
    height: 100% !important;
    width: 100% !important;
  }
  .wh-95 {
    height: 95% !important;
    width: 95% !important;
  }
  .wh-90 {
    height: 90% !important;
    width: 90% !important;
  }
  .wh-85 {
    height: 85% !important;
    width: 85% !important;
  }
  
  .ft-size-34 {
    font-size: 2.25rem !important;
  }
  .wh-28 {
    width: 3.48vh;
    height: 3.48vh;
  }
  .wh-30 {
    width: 3.68vh;
    height: 3.68vh;
  }
  .wh-35 {
    width: 3.98vh;
    height: 3.98vh;
  }
  .MuiModal-root {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .MuiPopover-root {
    background-color: transparent;
  }
  
  .wh-70 {
    width: 70vh;
    height: 3.68vh;
  }
  
  .wh-vw-vh {
    width: 100vw;
    height: 100vh;
  }
  wh-vw-vh-70-100 {
    width: 70vw;
    height: 100vh;
  }
  .wh-vw-vh-30 {
    width: 30vw;
    height: 30vh;
  }
  
  .app-style {
    height: 60px;
    background-color: #ffffff;
    color: #172b4d;
    box-shadow: 0rem 0.125rem 0.125rem rgba(0, 0, 0, 0.12);
  }
  
  .start-flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  
  .flex-between-start {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .mr-12 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .flex-center-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .flex-start-end {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .flex-center {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .flex-center-modal {
    display: flex !important;
    justify-content: center;
    align-items: flex-start;
    padding-top: 8vw;
  }
  .flex-center-modal-rost {
    display: flex !important;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1vw;
  }
  .all-page-container {
    height: 100%;
    max-height: 500px;
    overflow: auto;
  }
  .flex-column {
    flex-direction: column;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  .wrap-flex {
    flex-wrap: wrap;
  }
  .relative-white-bg {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    width: 460px;
  }
  .flex-end-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .end-flex {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .end-flex2 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .header {
    z-index: 2 !important;
    width: 100%;
    background-color: #ffffff !important;
    padding: 0rem 3.75rem;
    box-shadow: 0rem 0.125rem 0.125rem rgba(0, 0, 0, 0.12);
  }
  
  .header-logo {
    img {
      height: 3.68vh;
      width: 6.3125rem;
    }
  }
  
  .header-height {
    height: 3.75rem;
  }
  
  .header-image {
    width: 2.125rem;
    height: 2.125rem;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .header-icon {
    width: 24px;
    height: 24px;
    color: #172b4d;
    cursor: pointer;
  }
  
  .cursor-pointer {
    cursor: pointer !important;
  }
  
  .link-color {
    color: #335aff !important;
    fill: #335aff !important;
  }
  
  .modal-container-style {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  
    .container-padding {
      padding: 30px 30px;
    }
  
    .container-button {
      height: 65px;
      width: 100%;
      border-top: 0.0625rem solid #ebecf0;
      padding: 0rem 1rem;
    }
  }
  
  .modal-container-style2 {
    width: 62vh;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  
    .container-padding {
      padding: 30px 30px;
    }
  
    .container-button {
      height: 65px;
      width: 100%;
      border-top: 0.0625rem solid #ebecf0;
      padding: 0rem 1rem;
    }
  }
  .modal-container-style-req {
    width: 62vh;
    height: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  
    .container-padding {
      padding: 30px 30px;
    }
  
    .container-button {
      height: 65px;
      width: 100%;
      border-top: 0.0625rem solid #ebecf0;
      padding: 0rem 1rem;
    }
  }
  
  .modal-container-style-remap {
    width: 70vh;
    height: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  
    .container-padding {
      padding: 30px 30px;
    }
  
    .container-button {
      height: 65px;
      width: 100%;
      border-top: 0.0625rem solid #ebecf0;
      padding: 0rem 1rem;
    }
  }
  
  .modal-container-style-leave-preview {
    width: 1200px;
    height: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  
    .container-padding {
      padding: 30px 30px;
    }
  
    .container-button {
      height: 65px;
      width: 100%;
      border-top: 0.0625rem solid #ebecf0;
      padding: 0rem 1rem;
    }
  }
  
  .modal-container-style-leaveup-status {
    width: 60vh;
    height: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  
    .container-padding {
      padding: 30px 30px;
    }
  
    .container-button {
      height: 65px;
      width: 100%;
      border-top: 0.0625rem solid #ebecf0;
      padding: 0rem 1rem;
    }
  }
  
  .modal-container-style--asset-req {
    width: 50vw;
    height: 80%;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  
    .container-padding {
      padding: 30px 30px;
    }
  
    .container-button {
      height: 65px;
      width: 100%;
      border-top: 0.0625rem solid #ebecf0;
      padding: 0rem 1rem;
    }
  }
  
  .modal-container-style3 {
    width: 38vw;
    height: fit-content;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  
    .container-padding {
      padding: 27px 30px 30px 30px;
    }
  
    .container-button {
      height: 65px;
      width: 100%;
      border-top: 0.0625rem solid #ebecf0;
      padding: 0rem 1rem;
    }
  }
  
  .popover-header {
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12), 0px 3px 14px rgba(0, 0, 0, 0.1),
      0px 5px 5px rgba(0, 0, 0, 0.18) !important;
  }
  
  .ck.ck-editor {
    width: 100%;
  }
  
  .ck-editor__editable {
    min-height: 300px;
  }
  
  .modal-container-style4 {
    width: 70%;
    max-width: 600px;
    height: 63%;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  
    .container-padding {
      padding: 30px 30px;
    }
  
    .container-button {
      height: 65px;
      width: 100%;
      border-top: 0.0625rem solid #ebecf0;
      padding: 0rem 1rem;
    }
  }
  
  .modal-container-style5 {
    width: 70%;
    height: 63%;
    background-color: #ffffff;
    position: relative;
    &:focus {
      outline: none !important;
    }
  
    .container-padding {
      padding: 2vh 30px;
    }
  
    .container-button {
      height: 65px;
      width: 100%;
      border-top: 0.0625rem solid #ebecf0;
      padding: 0rem 1rem;
    }
  }
  
  .popover-calendar {
    height: 44.5vh !important;
    width: 41.23vh !important;
    padding: 1.96vh !important;
  }
  
  .list-popover {
    width: 13.75rem;
    background-color: #ffffff;
    cursor: pointer;
  
    .list-item {
      width: 100%;
      height: 48px;
      cursor: pointer;
      // white-space: nowrap;
      // overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
  
      .list-item-icon {
        min-width: 2rem !important;
        color: inherit;
      }
  
      &:hover {
        background-color: rgba(51, 90, 255, 0.05);
        color: #335aff !important;
      }
    }
  }
  
  li.Mui-selected{
    background-color: rgba(51, 90, 255, 0.05) !important;
    color: #335aff !important;
  }
  
  .mood-list-popover {
    width: 6.75rem;
    background-color: #ffffff;
    cursor: pointer;
  
    .list-item {
      width: 100%;
      height: 48px;
      cursor: pointer;
  
      .list-item-icon {
        min-width: 2rem !important;
        color: inherit;
      }
  
      &:hover {
        background-color: rgba(51, 90, 255, 0.05);
        color: #335aff !important;
      }
    }
  }
  .navigationArea {
    width: 350px;
    height: 100vh;
    // border-right: 1px solid #ebecf0;
  
    .link-navigateList {
      width: 100%;
      text-decoration: none;
      display: flex;
      padding: 0px 12px 0px 30px !important;
  
      .navigationList {
        width: 100%;
        height: 50px;
      }
  
      .collapse-button {
        width: 100%;
        padding: 0px 12px;
        height: auto;
      }
    }
  }
  
  .container {
    min-width: 100vw !important;
    height: calc(100vh - 60px) !important;
    margin-top: 60px !important;
  }
  
  .calcendar-page {
    width: 100%;
    height: 100%;
    padding: 0% 2%;
    margin: 0%;
    //overflow: scroll !important; /// Added because scroll not show on tab;e
    //max-height: 85vh !important; ///Added because in every page pagination not show
  
    .title-bar {
      height: 10vh;
    }
  
    .cal-page-container {
      width: 100%;
      height: 91vh;
  
      .cal-page-content {
        margin-right: 2.454vh !important;
  
        .create-custom-calender-container {
          height: 65%;
          width: 100%;
        }
  
        .emp-timeline {
          width: 100%;
          height: 35%;
          border-top: 0.0625rem solid #ebecf0;
        }
      }
  
      .second-container {
        margin-right: 2.454vh !important;
      }
    }
  }
  
  .border-bottom-tab {
    border-bottom: 1px solid #ebecf0;
  }
  
  .popover-ca0endar {
    height: 23.625rem !important;
    width: 21rem !important;
    padding: 1rem !important;
  }
  
  .clock-in {
    background-color: #34a853 !important;
    color: #fff !important;
  }
  
  .overflow-hidden {
    overflow: hidden !important;
    scrollbar-width: none;
  }
  
  .overflow-overlay {
    @include overflowAuto();
  }
  
  .icon-cover {
    width: 3.926vh;
    height: 3.926vh;
    background: #f8f8f8;
    border-radius: 3px;
  }
  .button-border {
    background: #f8f8f8;
    border-radius: 3px;
    width: 100% !important;
  }
  
  .box-radius {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    height: 2px;
    width: 100%;
  }
  
  .calendar-container {
    height: 72.392vh;
    width: 100%;
  }
  
  .last-container-width {
    width: 27vw;
    height: 72.392vh;
  }
  
  .calendar-card-size {
    height: 34.969vh;
    width: 100%;
  }
  
  .p-10-px {
    padding: 10px !important;
  }
  
  @media (max-width: 767px) {
    .overflow-hidden {
      @include overflowAuto();
    }
  }
  
  .MuiSvgIcon-root {
    color: currentColor;
    fill: currentColor;
  }
  
  .table {
    height: 100%;
    width: 100%;
  
    .keySortIcon {
      color: #757575;
      font-size: 7px;
    }
  
    .checkbox {
      background-color: #ffffff !important;
      font-size: 18px;
      color: #ebecf0 !important;
      width: fit-content;
  
      &:hover {
        color: #bdbdbd !important;
      }
    }
  
    .table-header {
      background-color: #ffffff !important;
    }
  
    .column-content {
      color: #172b4d !important;
  
      font-weight: 400 !important;
      font-size: 14px !important;
    }
  
    .table-cell-checkBox {
      width: max-content;
      min-width: 180px;
    }
  
    .table-cell-noCustom {
      min-width: max-content !important;
    }
  }
  
  .search-component {
    width: 220px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #ebecf0;
    border-radius: 3px;
    padding: 2px 12px;
    cursor: pointer;
  
    .icon {
      font-size: 18px;
      color: #172b4d;
      margin-top: 0px !important;
    }
  }
  
  .search-component-list {
    height: 35px;
    background: #ffffff;
    border: 1px solid #ebecf0;
    border-radius: 3px;
    padding: 2px 12px;
    cursor: pointer;
  }
  
  .search-component2 {
    width: 390px;
    height: 35px;
    background: #ffffff;
    border: 1px solid #ebecf0;
    border-radius: 3px;
    padding: 2px 12px;
    cursor: pointer;
  
    .icon {
      font-size: 18px;
      color: #172b4d;
      margin-top: 0px !important;
    }
  }
  
  .input-search {
    border: 0px !important;
    outline: none !important;
  
    &:focus {
      border: 0px !important;
      outline: none !important;
    }
  }
  
  .drawer-open {
    width: 100vw;
    height: 90vh;
    background-color: #fff;
  }
  
  .border-top-radius-10 {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  .table-image {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .custom-button {
    height: 35px;
    padding: 2px 12px 2px 14px;
    border-color: #ebecf0 !important;
  }
  
  .calendar-task-container {
    width: 100%;
    height: 576px;
    border: 1px solid #efefef;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  
    .title-bar {
      height: 56px !important;
      width: 100%;
      border-bottom: 1px solid #f0f0f0;
    }
  
    .calendar-container {
      width: 100%;
      height: calc(100% - 56px);
  
      .box-calendar-container {
        width: 100%;
        height: 100%;
      }
  
      .box-calendar-container-months {
        width: 100%;
        height: 100%;
  
        .list-column {
          height: 416px;
          overflow: hidden;
          border: 0.5px solid #efefef;
          border-radius: 6px !important;
        }
  
        .date-column {
          width: inherit;
          height: 100%;
          border: 0.5px solid #efefef;
          padding: 4px !important;
          cursor: pointer;
  
          .date-view {
            padding: 4px !important;
            width: 100%;
          }
  
          .date-first-child {
            justify-content: space-between;
          }
        }
      }
  
      .box-calendar-container-days-weeks {
        width: 100%;
        height: calc(100% - 56px);
  
        .week-days {
          width: 100%;
          height: 60px;
          padding: 0px 3.68vh 0px 11vh;
          border-bottom: 1px solid #f0f0f0;
        }
  
        .days-hours {
          width: 100%;
          height: 100%;
          @include overflowAuto();
  
          .hours-row {
            width: 100%;
            height: 7.36vh;
  
            .hours-text {
              width: 40px;
              text-align: center;
              position: relative;
              top: -6px;
            }
  
            .days-hours-row {
              height: 100%;
              width: 100%;
              border: 1px solid #efefef;
              border-top: 0.5px solid #efefef;
              border-bottom: 0.5px solid #efefef;
            }
  
            .hours-row-column {
              height: 7.36vh;
              width: 100%;
              border-top: 0.5px solid #efefef;
              border-bottom: 0.5px solid #efefef;
  
              .hours-column {
                width: 100%;
                border-left: 0.5px solid #efefef;
                border-right: 0.5px solid #efefef;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  
  .create-setting-list {
    width: 100%;
    height: 100%;
    padding: 2.68vh 1.47vh 0px 30px !important;
    border-right: 1px solid #efefef;
  
    .list-item {
      height: 48px;
      width: 100%;
      cursor: pointer;
  
      &:hover {
        background-color: rgba(51, 90, 255, 0.05);
        color: #335aff;
      }
    }
  }
  
  .create-Setting-container {
    width: 100%;
    height: 100%;
  
    .container-heading {
      height: 9.2vh;
      width: 100%;
    }
  }
  
  .create-new-page {
    width: 100%;
    height: 100%;
  
    .new-page-header {
      border-bottom: 1px solid #ebecf0;
      height: 7.73vh;
      width: 100%;
      padding: 0px 3.68vh;
    }
  
    .new-page-body {
      width: 100%;
      height: 100%;
      padding: 0px 21.18vw;
    }
  }
  .ck-source-editing-area {
    textarea {
      max-height: 50vh;
      overflow: auto;
    }
  }
  
  .create-leave-header {
    width: 100vw;
    height: calc(100vh - 60px);
  
    .stepper-area {
      width: 100%;
    }
  }
  
  .month-picker {
    height: 35px !important;
    width: 168px !important;
  
    .icon {
      width: 2.45vh;
      height: 2.45vh;
    }
  }
  
  .MuiStepper-root,
  .MuiStepper-horizontal {
    .MuiStepLabel-horizontal {
      .MuiStepLabel-root,
      .MuiStepLabel-iconContainer {
        .MuiStepIcon-active {
          color: #4dcf69 !important;
  
          .MuiStepIcon-text {
            fill: #4dcf69 !important;
            font-size: 12px !important;
          }
        }
  
        .MuiStepIcon-root {
          fill: transparent !important;
          width: 18px !important;
          height: 18px !important;
          display: inline-block;
          border: 2px solid currentColor !important;
          border-radius: 50% !important;
        }
  
        .MuiStepIcon-completed {
          color: #4dcf69 !important;
          fill: currentColor !important;
          width: 20px !important;
          height: 20px !important;
          border: 0px !important;
        }
  
        .MuiStepIcon-text {
          fill: #979797 !important;
  
          &:active {
            fill: #4dcf69 !important;
          }
        }
      }
  
      .selectLabelStepper {
        margin: 0px 8px !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        color: #172b4d !important;
      }
    }
  }
  
  .card__new {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    width: 100%;
    padding: 5px;
  }

  .modal-container {
    width: 650px;
    height: auto;
    border-radius: 5px;
    background-color: #ffffff;
    position: relative;
    .modal-component {
      width: 100%;
      height: 100%;
      padding: 3.68vh 3.68vh 1.84vh;
      position: relative;
      .clearIcon {
        color: #172b4d;
        font-size: 18px;
      }
  
      .download-template-card {
        width: 100%;
        height: auto;
        padding: 2.94vh;
        border: 1px solid #ebecf0;
        border-radius: 6px;
        margin: 2.15vh 0px 2.28vh;
  
        .download-button {
          border: 1px solid #ebecf0;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 10px;
          color: #335aff !important;
          font-size: 14px !important;
          font-weight: 500;
        }
      }
  
      .import-file {
        border: 1px solid #ebecf0;
        border-radius: 6px;
        width: 100%;
        height: auto;
        padding: 2.94vh;
      }
  
      .dragger-file-area {
        margin-top: 2.94vh;
        width: 100%;
        height: 11.9vh;
        border: 2px dashed #d8d8d8;
        background-color: #f4f8fc;
        padding: 2.94vh 0px;
        border-radius: 6px;
        cursor: pointer;
      }
    }
  
    .custom-button-modal {
      border-top: 1px solid #ebecf0;
      width: 100%;
      height: auto;
      padding: 1.84vh 3.68vh;
      position: relative;
    }
  
    &:focus {
      outline: none !important;
    }
  }
  
  .leave-modal-container {
    width: 460px;
    height: 585px;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    .upper-container {
      width: 100%;
      height: calc(100vh - 300px);
    }
  
    .custom-button-modal {
      border-top: 1px solid #ebecf0;
      width: 100%;
      height: auto;
      padding: 15px 30px;
      position: relative;
    }
  
    &:focus {
      outline: none !important;
    }
  }
  
  .leave-modal-container2 {
    width: 460px;
    height: 585px;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    .upper-container {
      width: 100%;
      height: calc(100% - 65px);
    }
  
    .custom-button-modal {
      border-top: 1px solid #ebecf0;
      width: 100%;
      height: auto;
      padding: 15px 30px;
      position: relative;
    }
  
    // &:focus {
    //   outline: none !important;
    // }
  }
  
  .reassign-modal-container {
    width: 460px;
    height: 425px;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    .upper-container {
      width: 100%;
      height: calc(100% - 65px);
    }
  
    .custom-button-modal {
      border-top: 1px solid #ebecf0;
      width: 100%;
      height: auto;
      padding: 15px 30px;
    }
  
    &:focus {
      outline: none !important;
    }
  }
  
  // ----------------------------------------------------------------------------
  
  .iconButton {
    width: 35px;
    height: 35px;
    background: #f8f8f8;
    border-radius: 3px;
    color: #172b4d;
    cursor: pointer;
  }
  
  .MuiTableRow-root:hover {
    background: rgba(248, 248, 248, 0.4) !important;
  }
  
  // fixed column column table with containrt
  
  .fixed-table {
    width: inherit;
    height: inherit;
    margin: 0px;
    padding: 0px;
    font-family: "Segoe UI Web (West European)";
  font-style: normal;
  
    .MuiTablePagination-toolbar {
      padding: 0px !important;
      margin: 0px !important;
  
      .MuiIconButton-root {
        color: inherit;
        padding: 5px;
  
        &:disabled {
          color: #bdbdbd;
        }
      }
    }
  
    .table-container {
      @include overflowAuto();
      width: 100%;
      border: 1px solid #ebecf0;
      font-family: "Segoe UI Web (West European)";
      font-style: normal;
  
      .table-container-shadow {
        box-shadow: inset -3px 0px 6px -3px rgba(115, 115, 115, 0.2);
      }
  
      // border-bottom: 1px solid #f0f0f0;
      table {
        table-layout: fixed !important;
  
        .table-row {
          height: auto !important;
        }
        font-family: "Segoe UI Web (West European)";
        font-style: normal;
  
        td,
        th {
          border-bottom: 1px solid #ebecf0;
          padding: 0px;
          padding-left: 0.8%;
          font-family: "Segoe UI Web (West European)";
          font-style: normal;
        }
  
        td,
        th:first-child {
          padding-left: 10px !important;
        }
  
        .fixed-table-cell {
          width: 140px !important;
          height: auto !important;
        }
  
        // .fixed-table-cell-height {
        //   height: auto !important;
        //   // width: auto !important;
        // }
  
        .fixed-table-cell-height-new {
          height: auto !important;
          padding: 18px 10px !important;
        }
  
        .fixed-column-header {
          height: 50px;
          background-color: #f5f5f5 !important;
          padding-left: 10px;
        }
  
        .width-150px {
          width: 150px !important;
        }
  
        .width-140px {
          width: 140px !important;
        }
        .width-164px {
          width: 164px !important;
        }
  
        .width-200px {
          width: 180px !important;
        }
  
        .fixed-table-header {
          width: 50px !important;
          height: 50px !important;
          color: #ebecf0;
        }
  
        .fixed-table-checkbox {
          width: 50px !important;
          height: 50px !important;
          color: #ebecf0;
        }
  
        .fixed-icon-cell {
          width: 40px !important;
          height: 40px !important;
          //background-color: #fff;
          // box-shadow: -3px 0px 6px rgba(115, 115, 115, 0.2);
        }
  
        .fixed-column {
          position: sticky;
          background-color: #fff;
        }
  
        .last-cell-shadow {
          box-shadow: 2px 0px 1px -1px rgba(115, 115, 115, 0.2);
        }
      }
  
      &:hover {
        cursor: pointer;
      }
    }
  }
  
  .dashboardContainer {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
  
    .title-bar {
      height: 72px;
      width: 100%;
  
      .active {
        color: #335aff;
  
        &:hover {
          color: #335aff;
        }
      }
  
      .icon-button {
        color: #757575;
  
        &:hover {
          color: #335aff;
        }
      }
    }
  }
  
  .onboarding-container {
    width: 100%;
    height: 100%;
  
    .title-bar-onboarding {
      height: 7.36vh;
      width: 100%;
      padding: 0% 4.5%;
    }
  
    .onboarding-grid-container {
      width: 100% !important;
      height: calc(100% - 7.36vh) !important;
    }
  }
  
  .right-container {
    border: 1px solid #ebecf0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }
  
  .grid-container {
    // padding: 0% calc(4.5% - 10px);
    width: 100%;
    height: 100%;
  
    // padding-bottom: 11vh;
    .card-with-calendar {
      width: 100%;
      height: 320px;
      border: 1px solid #ebecf0;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      background-color: white;
  
      .card-title-area {
        height: 50px !important;
        width: 100%;
        padding: 5px 15px;
      }
  
      .text-container {
        width: 26px;
        height: 26px;
        background: rgba(51, 90, 255, 0.1);
        border-radius: 50%;
      }
  
      .card-content-container {
        width: 100%;
        height: calc(100% - 56px);
        padding: 0.5vh 2.45vh 2.45vh 3.68vh;
      }
    }
  }
  
  .custom-image-avatar {
    width: 3vh !important;
    height: 3vh !important;
  }
  
  .dashboard-popover {
    width: 36.81vh;
    height: 61.34vh;
    padding-bottom: 20px;
  
    .title-popover {
      width: calc(100% - 4.9vh);
      height: 6.13vh;
    }
  
    .dash-boardlist-popover {
      width: 100%;
      height: calc(100% - 6.13vh);
  
      .dashboard-listItem {
        width: 100%;
        height: 40px;
        padding: 0px 2.45vh 0px 1.23vh;
  
        .drag-icon {
          color: #d8d8d8;
        }
  
        .checkbox-listColor {
          color: #ebecf0;
        }
  
        &:hover {
          cursor: pointer;
  
          .drag-icon {
            color: #172b4d;
          }
  
          .checkbox-listColor {
            color: #172b4d;
          }
        }
      }
    }
  }
  
  // Start of Basant's CSS
  .engage {
    .flip {
      transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
    }
  
    .start-flex {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  
    .flex-between-start {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  
    .flex-between-center {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .flex-around-center {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .flex-start-center {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  
    .flex-center-start {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
    .flex-space-evenly {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  
    .flex-center {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  
    .flex-column {
      flex-direction: column;
    }
    .dialogTitle {
      h2 {
        span {
          font-size: "50px";
        }
      }
    }
  
    .wrap-flex {
      flex-wrap: wrap;
    }
  
    .flex-end-center {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  
    .end-flex {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .mr-15 {
      margin-right: 15px;
    }
  
    .mt-8px {
      margin-top: 8px;
    }
  
    .mt-25 {
      margin-top: 25px;
    }
  
    .txt-muted {
      color: #979797 !important;
    }
  
    .w-80 {
      width: 80% !important;
    }
  
    .w-45 {
      width: 45% !important;
    }
  
    .w-90 {
      width: 90% !important;
    }
  
    .w-10 {
      width: 10% !important;
    }
  
    .w-50 {
      width: 50% !important;
    }
    .flex-basis-45 {
      flex: 0 1 45% !important;
    }
    .test {
      display: flex;
    }
    .test > div {
      flex: 0 1 45% !important;
      margin-right: 10px;
    }
    .display-none {
      visibility: hidden !important;
      display: none !important;
    }
    .rightsidebar-heading {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #172b4d;
    }
  
    .hashtag {
      font-size: 16px;
      font-weight: 500;
      color: #335aff;
    }
    .ellipsisBox {
      height: 49px;
      color: "#757575";
      margin-top: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      // display: -webkit-box !important;
      // -webkit-line-clamp: 3;
      // -webkit-box-orient: vertical;
      // white-space: normal;
    }
    .text-ellipsis {
      width: 100px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .annoucements {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #172b4d;
    }
  
    .create-post-toolbar {
      background: rgba(248, 248, 248, 0.5);
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
    }
  
    .border-bottom {
      border-bottom: 1px solid #f0f0f0;
    }
  
    .create-post-content {
      border: none;
    }
  
    .create-post-toolbar {
      background: rgba(248, 248, 248, 0.5);
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
    }
  
    .border-bottom {
      border-bottom: 1px solid #f0f0f0;
    }
  
    .create-post-content {
      border: none;
    }
  
    .engage-container {
      padding-top: 10px;
      padding-left: 50px;
      padding-right: 50px;
  
      .content-area {
        width: 70% !important;
        padding: 15px;
  
        .filter {
          .active {
            background-color: #f4f6fe !important;
            color: #335aff !important;
            margin-right: 6px;
          }
  
          .default {
            background-color: #f8f8f8 !important;
            margin-right: 6px;
          }
  
          .all-post {
            background-color: #f8f8f8;
            padding: 0px 10px 0px 10px;
            border-radius: 100px;
  
            .dropdown-icon {
              width: 20px;
              margin-left: 2px;
            }
          }
  
          .sorting {
            background-color: #f8f8f8;
            padding: 5px;
            border-radius: 50%;
          }
        }
      }
  
      .right-sidebar {
        width: 30% !important;
        padding: 15px;
      }
    }
  }
  
  // End of Basant's CSS
  
  // create modal css start
  
  .create_post_modal .modal-container-style4 {
    height: auto !important;
    padding-bottom: 20px;
  }
  
  .custom_popover .list-popover {
    top: 50%;
    left: 50%;
    transform: translateX(-343px) translateY(99px);
    cursor: pointer;
  }
  
  // //////////////////////////////
  // Rahul's Style Start
  ////////////////////////////////
  $primary: #335aff;
  $secondary: #fac800;
  
  .imgFluid {
    max-width: 100%;
  }
  
  .shiftDialog {
    width: 461px;
    max-width: 100%;
    margin: auto;
  
    .titleWrapper {
      // padding-bottom: 30px;
      span {
        font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
          sans-serif;
        font-weight: 600;
        font-size: 16px;
      }
  
      img {
        width: 14px;
        height: 14px;
      }
  
      .ibWrap {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
    }
  
    .bodyWrapper {
      padding-top: 20px;
      padding-bottom: 40px;
  
      .fieldWrap {
        padding: 8px 12px !important;
  
        label {
          font-size: 12px;
          font-weight: 500;
        }
  
        input {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.01em;
        }
  
        .posWrap {
          position: relative;
  
          .colorPlate {
            position: absolute;
            width: 40px;
            height: 20px;
            border-radius: 3px;
            right: 15px;
            top: 23px;
            background-color: $secondary;
          }
        }
  
        .selectWrap {
          .selectMenu {
            margin: 8px 0;
  
            > div {
              padding: 14px 14px;
  
              ul {
                display: flex;
                flex-direction: column;
  
                li {
                  justify-content: flex-start;
                  padding: 6px;
                }
              }
            }
  
            svg {
              display: none;
            }
          }
        }
  
        fieldset {
          border-width: 1px;
          // height: 52px;
        }
  
        .timeZone {
          top: 7px;
        }
      }
  
      .labelWrap {
        padding-bottom: 4px;
  
        label {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #172b4d;
          padding-left: 2px;
        }
      }
  
      .chip {
        margin: auto !important;
        background-color: #f8f8f8;
        color: #757575;
        border-color: #f8f8f8;
        cursor: pointer;
      }
  
      .chip.active {
        background: #e3ebfd;
        color: #335aff;
        border-color: #e3ebfd;
      }
  
      .buttonGroup {
        width: 100%;
  
        .inputWrap {
          position: relative;
          width: 220px;
  
          > div {
            width: 220px;
          }
  
          .calendarWrap {
            position: absolute;
            right: 18px;
            top: 50%;
            transform: translateY(-50%);
          }
  
          fieldset {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-width: 1px;
          }
        }
  
        .selectGroup {
          width: calc(100% - 220px);
  
          > div {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
  
            > div {
              padding: 16px 14px;
            }
          }
  
          fieldset {
            border-width: 1px;
          }
        }
      }
  
      .weekOff {
        font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
          sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #172b4d;
        padding-bottom: 8px;
      }
    }
  
    .footWrapper {
      border-top: 1px solid #ebecf0;
      // margin-top: 30px;
      padding: 15px 30px;
  
      .btnMore {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        height: 35px;
        margin-right: 5px;
      }
  
      .btnDone {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        height: 35px;
      }
    }
  }
  
  .moreOptionsDialog {
    > div > div {
      border-radius: 0;
    }
  
    .appBar {
      // border-radius: 0;
    }
  
    header {
      box-shadow: none;
      margin-bottom: 40px;
    }
  
    .fieldWrap {
      > div {
        margin-bottom: 30px;
      }
  
      .posWrap {
        position: relative;
  
        .colorPlate {
          position: absolute;
          width: 40px;
          height: 20px;
          border-radius: 3px;
          right: 15px;
          top: 23px;
          background-color: $secondary;
        }
      }
    }
  
    .label {
      display: inline-block;
      font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #172b4d;
      margin-bottom: 8px;
    }
  }
  
  .modal-component-live-type {
    width: 461px;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
  }
  
  .modal-component-report-type {
    width: 90vw;
    height: 80vh;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
  }
  
  .disable-field {
    width: 100%;
    background: #f8f8f8;
    border-radius: 6px;
    height: 50px;
    padding: 17px;
  }
  
  .file-card {
    background: #ffffff;
    border: 1px solid #ebecf0;
    box-sizing: border-box;
    height: 50px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }
  
  .modal-style-shift {
    width: 461px;
    max-height: 90vh;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  .modal-style-shift-assets {
    width: 461px;
    max-height: 100vh;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  .modal-style-shift-leave-type {
    width: 461px;
    height: 514px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .leave-deduct-modal {
    width: 301px;
    max-height: 90vh;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .width-50-10px {
    width: calc(50% - 10px) !important;
  }
  
  .width-75-10px {
    width: calc(75% - 10px) !important;
  }
  
  .width-50-15px {
    width: calc(50% - 15px) !important;
  }
  
  .width-50-16px {
    width: calc(50% - 16px) !important;
  }
  .width-40 {
    width: 40%;
  }
  
  .width-40-16px {
    width: calc(40% - 16px) !important;
  }
  
  .width-100 {
    width: 100%;
  }
  
  .width-25-10px {
    width: calc(25% - 10px);
  }
  
  .width-20-10px {
    width: calc(20% - 10px);
  }
  
  .emp-stepper {
    width: 100%;
    height: 100%;
  
    .emp-create-from-list {
      height: 60% !important;
      width: 65vw;
  
      .emp-create-from {
        flex-wrap: wrap;
        list-style: none;
  
        li:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  
  .more-option-cancel {
    top: -204px;
    left: calc(100% - 16px);
  }
  
  .moreoption-block {
    width: 100%;
    height: 190px;
    border: 1px solid #ebecf0;
    border-radius: 6px;
  
    .block-first-child {
      width: calc(45% - 1px);
      height: 100%;
    }
  
    .block-second-child {
      width: 55%;
      height: 100%;
    }
  }
  
  .table-org-master {
    width: 100%;
    border-radius: 6px !important;
  
    .tableHead {
      background-color: #f8f8f8;
    }
  
    .tableCell {
      border: 0.5px solid #ebecf0;
      min-width: 140px;
    }
  }
  
  .input-select {
    border: 0px !important;
    outline: none !important;
    width: 100%;
  
    &:focus {
      border: 0px !important;
      outline: none !important;
    }
  
    .option {
      height: 48px;
    }
  }
  
  .other-setting-card {
    width: 100%;
    border: 1px solid #ebecf0;
    border-radius: 6px;
  }
  
  .other-sandwich-card {
    width: 85%;
    border: 1px solid #ebecf0;
    border-radius: 6px;
  }
  
  .modal-component-leave-rules {
    width: 461px;
    height: auto;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .modal-component-leave-rules-2 {
    width: 600px;
    // height: 600px;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .modal-component-leave-rules_width {
    width: 100%;
  }
  
  .modal-component-leave-rules-height {
    width: 461px;
    height: 100%;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .popover-timeRanger {
    width: 400px;
    height: 250px;
  
    background: #ffffff;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12), 0px 3px 14px rgba(0, 0, 0, 0.1),
      0px 5px 5px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
  
    .input-box {
      height: 50px;
      width: 40px;
      border: 1px solid #bdbdbd;
      border-radius: 6px;
  
      .input-type {
        width: 30px;
        height: 24px;
        text-align: center;
      }
    }
  }
  
  .doubbleForm {
    color: #979797;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
  }
  .doubbleValue {
    color: #979797;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
  }
  .filter-screen-container {
    // width: 100vw;
    height: calc(100vh - 108px);
  }
  
  .drawer-container {
    width: 260px;
    height: inherit;
    border-left: 1px solid #ebecf0;
    background: #fcfcfc;
    transition: 0.5s;
  }
  
  .tab-containter {
    width: 100vw;
    height: calc(100vh - 61px);
  }
  
  .work-form-modal {
    width: 460px;
    height: 433px;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
  }
  
  .daterange_parent {
    position: relative;
    width: 100%;
  }
  
  .close_icon {
    position: absolute !important;
    top: -11px;
    right: -11px;
    z-index: 1;
    background: #fff !important;
    width: 22px;
    height: 22px;
  }
  
  .close_icon svg {
    width: 15px;
    height: 15px;
  }
  
  .form-group > label {
    top: 38px;
    left: 15px;
    position: relative;
    background-color: white;
    padding: 0px 5px 0px 5px;
    font-size: 1.1em;
    transition: 0.2s;
    pointer-events: none;
  }
  
  .form-group.label-animate > label {
    top: 18px !important;
    left: 16px !important;
    font-size: 0.9em;
  }
  
  .border-position {
    //border: 1px solid #bdbdbd;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    position: relative;
  }
  
  .legend_text {
    position: absolute;
    top: -8px;
    left: 12px;
    background-color: #fff;
    padding: 0px 7px;
  }
  
  .transform-rotate-45 {
    transform: rotate(45deg);
  }
  
  .calendar_list {
    list-style: none;
    padding-left: 0px;
  }
  
  .calendar_list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    color: #172b4d;
  }
  
  .calendar_list li:last-child {
    margin-bottom: 0;
  }
  
  .input-field {
    height: 50px;
    border-radius: 6px;
  }
  
  .input-field-date {
    height: 33px;
    border-radius: 6px;
  }
  
  .expand_button {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .expand_button svg {
    transform: rotate(-140deg);
  }
  
  .card-shadow {
    border-radius: 6px;
    border: 1px solid #ebecf0;
    background: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }
  
  .card-shadow-2 {
    border-radius: 6px;
    border: 1px solid #ebecf0;
    background: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }
  
  .admin-tableheight {
    height: calc(100vh - 201px);
  }
  .admintableheightnew {
    height: calc(100vh - 170px);
  }
  
  .admin-tableheight3 {
    height: calc(100vh - 180px);
  }
  
  .admintablescroll {
    height: calc(100vh - 205px);
  }
  .admintablescroll2 {
    height: calc(100vh - 230px);
  }
  
  .adminscrollnopagination {
    height: calc(100vh - 220px);
  }
  
  .ticket-tableheight {
    height: calc(85vh - 201px);
  }
  
  .employee-tableHeight {
    height: calc(100vh - 122px);
  }
  .scrollbar-opacity > div:nth-last-child(-n + 2) {
    opacity: 0;
  }
  .scrollbar-opacity:hover > div:last-child,
  div:nth-last-child(2) {
    opacity: 1;
  }
  .css-6wy23n-MuiPaper-root-MuiPopover-paper {
    max-height: calc(100% - 93px) !important;
  }
  .celebrations {
    height: 60px;
    padding-left: 10px;
    background: rgba(51, 90, 255, 0.03);
    border: 1px solid rgba(51, 90, 255, 0.1);
    margin-bottom: 10px;
    // max-width: 425px;
    border-radius: 4px;
  }
  .card-styles-company-dashboard {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: dense;
    grid-template-rows: repeat(3, 330px);
  
    grid .grid-area-1 {
      grid-area: larger;
    }
  
    .grid-area-2 {
      grid-area: larger;
    }
  
    .grid-area-3 {
      grid-area: smaller;
    }
  
    .grid-area-4 {
      grid-area: smaller;
    }
  
    .grid-area-5 {
      grid-area: smaller;
    }
  
    .grid-area-6 {
      grid-area: smaller;
    }
  }
  
  .card-styles-team-dashboard {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: dense;
    grid-template-rows: repeat(3, 450px);
  
    .grid-area-1 {
      grid-area: larger;
    }
  
    .grid-area-2 {
      grid-area: larger;
    }
  
    .grid-area-3 {
      grid-area: larger;
    }
  
    .grid-area-4 {
      grid-area: larger;
    }
  
    .grid-area-5 {
      grid-area: larger;
    }
  
    .grid-area-6 {
      grid-area: larger;
    }
  }
  .container-LeftArea {
    width: calc(100vw - 25px);
    height: calc(100vh - 120px);
  }
  
  .cardList {
    width: 95%;
    border: 1px solid #ebecf0;
    border-radius: 8px;
    cursor: pointer;
  
    .subcard {
      width: 100%;
      border-top: 1px solid #ebecf0;
      padding: 20px 0px;
      height: 32px;
  
      .selectedCard {
        height: 32px;
        min-width: 70px;
        padding: 0px 20px;
        background-color: #f8f8f8;
        border: 1px solid #ebecf0;
        border-radius: 3px !important;
      }
    }
  }
  
  .clubbing-modal {
    width: 461px;
    height: 437px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    .container-height {
      width: 100%;
      height: calc(100% - 66px);
    }
  
    .bottom-container {
      width: 100%;
      padding: 15px 30px;
      height: 65px;
    }
  
    &:focus {
      outline: none !important;
    }
  }
  
  .sandwitch-container {
    min-width: 58vw;
    width: 80%;
    height: auto;
  }
  
  // .css-8cszq1 {
  //   height: auto !important;
  //   margin-top: 13px;
  //   display: block;
  // }
  // .create-Setting-container table {
  //   margin-bottom: 70px;
  // }
  
  // navneet style
  
  .question_option {
    .MuiFormControlLabel-label {
      line-height: 27px;
      color: #172b4d !important;
    }
  }
  
  .companyList {
    .MuiListItemIcon-root {
      min-width: 40px !important;
    }
  }
  
  .button-container {
    width: 100%;
    height: 65px;
  }
  
  .table-row {
    height: 50px;
    border-top: 1px solid #f0f0f0;
  }
  
  .candidate-list-card {
    margin-bottom: 10px;
  
    &:last-child {
      margin-bottom: 0px;
    }
  }
  
  .onboarding-column-direction {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px;
    overflow: auto;
    //height: 67vh !important;
    height: calc(100vh - 260px);
  }
  
  .modal-container-style-regularizenew{
    width: 70vw;
      background-color: #fff;
      border-radius: 5px;
      position: relative;
  }
  
  
  .modal-container-style-regularize {
    width: 91vw;
    height: 85vh;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .modal-container-style-delete-wfh {
    width: 35vw;
    height: 65vh;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .modal-container-style-library {
    width: 30vw;
    height: 70vh;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .modal-container-style-on-duty {
    width: 460px;
    height: 460px;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .modal-container-style-rh {
    width: 460px;
    height: 300px;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .modal-container-style-shl {
    width: 460px;
    height: 450px;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .modal-container-style-mapper {
    width: 410px;
    height: auto;
    max-height: 571px;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .date-ranger-selectOption {
    width: 100%;
  
    font-size: 14px;
    color: #172b4d;
    padding-left: 14px;
    border: 0px;
    outline: none;
  
    &:focus {
      border: 0px;
      outline: none;
    }
  }
  
  option {
    font-size: 14px;
    color: #172b4d;
    outline: none;
    height: 46px;
    padding: 16px 14px;
  }
  
  // option:hover {
  //   color: blue;
  // }
  .MuiDateRangePickerInput-root {
    width: 100%;
  }
  
  .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // grid-gap: 16px;
    // grid-auto-rows: minmax(200px, auto);
  }
  
  .plan-details {
    grid-column: 2/4;
  }
  
  .tds-projection {
    grid-column: 1/3;
  }
  
  .salary-breakup {
    grid-column: 1/3;
    grid-row: 1/3;
  }
  
  .salary-breakup-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-weight: 600;
    color: #172b4d;
  }
  
  .comp-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  
    font-weight: 500;
    color: #172b4d;
  }
  
  .loan-details {
    max-height: 120px;
    // min-height: 120px;
    overflow: auto;
    padding: 20px;
  }
  
  .salary-details {
    max-height: 400px;
    min-height: 400px;
    overflow: auto;
    padding: 20px;
  }
  
  .salary-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font-weight: 700;
    color: #172b4d;
  }
  
  .salary-details-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .form12-title {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-weight: 600;
    color: #172b4d;
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    padding-bottom: 0px;
  }
  
  .form12-desc {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .form12-desc-title {
    margin-bottom: 15px;
    padding: 20px;
  
    font-weight: 400;
    line-height: 19px;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .monthly-tax-sheets-title {
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #172b4d;
  }
  
  .monthly-tax-sheets-desc {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  
    font-style: normal;
    font-weight: 400;
    color: #172b4d;
    overflow: auto;
    max-height: 233px;
    margin: 10px 0px 5px 0px;
  }
  
  .modal-container-style-workflow {
    width: 460px;
    height: 396px;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    .active-button {
      width: 113px;
      height: 35px;
      background: #f8f8f8;
      border-radius: 3px;
    }
  
    &:focus {
      outline: none !important;
    }
  }
  
  .workflow-drawer {
    width: 450px;
    height: calc(100vh - 60px);
    background-color: #fff;
    border-left: 1px solid #ebecf0;
  
    .list-action-type {
      width: 100%;
      border: 1px solid #ebecf0;
      border-radius: 6px;
      padding: 20px;
      height: 60px;
      margin-bottom: 10px;
      cursor: pointer;
  
      &:last-child {
        margin-bottom: 0px;
      }
    }
  
    .appAPproval {
      width: 100%;
      height: auto;
      padding: 18px;
      margin-bottom: 20px;
      border: 1px solid #ebecf0;
      border-radius: 6px;
    }
  }
  
  .selectType {
    width: 100%;
    height: 44px;
    padding: 10px 13px;
    background: #f8f8f8;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  
  // tree css
  
  .flow_border_1 {
    display: inline-block;
    border-left: 1px dashed #bdbdbd;
    height: 15px;
  }
  .btn_addMore_1 {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #bdbdbd;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 12px;
    color: #757575;
    z-index: 2;
    cursor: pointer;
  
    &:hover {
      border-color: #335aff;
      color: #335aff;
      background-color: #fff;
    }
  }
  
  .tree {
    $color: #172b4d;
    $border-color: #bdbdbd;
    $background-color: transparent;
    $border-weight: 1px;
    $margin: 30px;
  
    padding: 0;
    white-space: nowrap;
    &:not(:empty):before,
    &:not(:empty):after,
    ul:not(:empty):before,
    ul:not(:empty):after,
    li:not(:empty):before,
    li:not(:empty):after {
      display: block;
      position: absolute;
      content: "";
    }
  
    ul,
    li {
      position: relative;
      margin: 0;
      padding: 0;
    }
  
    li {
      list-style: none;
      // min-width: 300px;
    }
  
    li > div {
      background-color: $background-color;
      color: $color;
      padding: 5px;
      display: inline-block;
    }
  
    &.vertical {
      display: flex;
  
      ul {
        display: flex;
        justify-content: center;
      }
  
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
  
        div {
          padding: $margin $margin / 2;
          div {
            padding: 10px 20px;
          }
        }
  
        &:before {
          border-left: $border-weight solid $border-color;
          height: $margin;
          width: 0;
          top: 0;
        }
  
        .active-remove::before {
          height: 0px !important;
        }
        &:after {
          border-top: $border-weight solid $border-color;
          height: 0;
          width: 100%;
        }
  
        &:first-child:after {
          border-top: $border-weight solid $border-color;
          height: 0;
          width: 50%;
          left: 50%;
        }
  
        &:last-child:after {
          border-top: $border-weight solid $border-color;
          height: 0;
          width: 50%;
          right: 50%;
        }
  
        &:only-child:after {
          content: none;
        }
  
        ul:before {
          border-left: $border-weight solid $border-color;
          height: $margin;
          width: 0;
          top: -$margin;
        }
      }
  
      & > li {
        &:only-child:before,
        &:only-child:after {
          content: none;
        }
      }
    }
  
    %subcascade {
      flex-direction: column;
      align-items: start;
      padding: 0 $margin;
  
      &:before {
        left: 2 * $margin;
      }
  
      &:after {
        left: 0;
      }
  
      &:first-child:after {
        left: 2 * $margin;
        width: 100%;
      }
  
      &:last-child:after {
        left: 0;
        width: 2 * $margin;
      }
  
      ul,
      li {
        display: block;
      }
  
      ul:before,
      ul:after,
      li:before,
      li:after {
        border: none;
      }
  
      div {
        margin: 0;
        margin-top: $margin;
      }
  
      li {
        margin-left: 2 * $margin;
  
        &:before {
          border-left: $border-weight solid $border-color;
          height: 100%;
          width: 0;
          top: 0;
          left: -$margin;
        }
  
        &:after {
          border-top: $border-weight solid $border-color;
          width: $margin;
          height: 0;
          left: -$margin;
          top: $margin * 2;
          content: "";
        }
  
        &:last-child:before {
          height: $margin * 2;
          top: 0;
        }
      }
    }
  }
  
  // .tree ul {
  //   padding-top: 30px;
  //   position: relative;
  //   transition: all 0.5s;
  //   -webkit-transition: all 0.5s;
  //   -moz-transition: all 0.5s;
  // }
  
  // .tree li {
  //   text-align: center;
  //   list-style-type: none;
  //   position: relative;
  //   padding: 30px 5px 0 5px;
  //   transition: all 0.5s;
  //   -webkit-transition: all 0.5s;
  //   -moz-transition: all 0.5s;
  //   /*added for long names*/
  
  //   float: none;
  //   display: inline-block;
  //   vertical-align: top;
  //   white-space: nowrap;
  //   margin: 0 -2px 0 -2px;
  // }
  
  // /*We will use ::before and ::after to draw the connectors*/
  
  // .tree li::before,
  // .tree li::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 50%;
  //   border-top: 1px solid #bdbdbd;
  //   width: 50%;
  //   height: 30px;
  // }
  
  // .tree li::after {
  //   right: auto;
  //   left: 50%;
  //   border-left: 1px solid #bdbdbd;
  // }
  
  // /*We need to remove left-right connectors from elements without
  // any siblings*/
  
  // .tree li:only-child::after,
  // .tree li:only-child::before {
  //   display: none;
  // }
  
  // /*Remove space from the top of single children*/
  
  // .tree li:only-child {
  //   padding-top: 0;
  // }
  
  // /*Remove left connector from first child and
  // right connector from last child*/
  
  // .tree li:first-child::before,
  // .tree li:last-child::after {
  //   border: 0 none;
  // }
  
  // /*Adding back the vertical connector to the last nodes*/
  
  // .tree li:last-child::before {
  //   border-right: 1px solid #bdbdbd;
  // }
  
  // .tree li:first-child::after {
  // }
  
  // /*Time to add downward connectors from parents*/
  
  // .tree ul::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: calc(50% - 1px);
  //   border-left: 1px solid #bdbdbd;
  //   width: 0;
  //   height: 30px;
  // }
  
  // .tree ul ul::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: calc(50% - 1px);
  //   border-left: 1px solid #bdbdbd;
  //   width: 0;
  //   height: 30px;
  // }
  
  // .tree li a {
  //   border: 1px dashed #bdbdbd;
  //   padding: 5px 10px;
  //   text-decoration: none;
  //   color: #172b4d;
  
  //   font-size: 11px;
  //   display: inline-block;
  
  //   transition: all 0.5s;
  //   -webkit-transition: all 0.5s;
  //   -moz-transition: all 0.5s;
  // }
  
  // /*Time for some hover effects*/
  // /*We will apply the hover effect the the lineage of the element also*/
  
  // .tree li a:hover,
  // .tree li a:hover + ul li a {
  //   background: transparent;
  //   color: #172b4d;
  //   border: 1px solid #bdbdbd;
  // }
  // .tree {
  //   white-space: nowrap;
  //   overflow: auto;
  // }
  
  // /*Connector styles on hover*/
  
  // .tree li a:hover + ul li::after,
  // .tree li a:hover + ul li::before,
  // .tree li a:hover + ul::before,
  // .tree li a:hover + ul ul::before {
  //   border-color: #94a0b4;
  // }
  
  // .husband {
  //   float: left;
  // }
  
  // .wife {
  //   margin-left: 10px;
  // }
  
  // .wife::before {
  //   /* pseudo CSS, will need to be modified */
  
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 50%;
  //   border-top: 1px dashed #BDBDBD;
  //   width: 50%;
  //   height: 20px;
  // }
  
  .workflow-setting-page {
    width: 100vw;
    height: calc(100vh - 60px);
  
    .inner-setting-container {
      width: 100%;
      height: 100%;
      border: 1px solid #ebecf0;
      border-radius: 6px;
    }
  
    .checkbox-page {
      height: 50px;
      width: 100%;
      //background: #f8f8f8;
      background: #dadada;
      border-radius: 6px;
      padding: 12px;
    }
  }
  
  .modal-container-style-workflow-history {
    width: 500px;
    height: 78vh;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    .active-button {
      width: 113px;
      height: 35px;
      background: #f8f8f8;
      border-radius: 3px;
    }
  
    &:focus {
      outline: none !important;
    }
  }
  
  .modal-container-style-withdrawel {
    width: 500px;
    height: 322px;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .tds-details {
    display: flex;
    flex-direction: row;
    height: 225px;
  }
  
  .monthly-tax-sheets-title-2 {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #172b4d;
  }
  
  .my-salary-wrapper {
    width: 100%;
    height: 586px;
    background: #ffffff;
    border: 1px solid #ebecf0;
    box-sizing: border-box;
    border-radius: 6px;
  }
  
  .salary-body-1 {
    display: flex;
    justify-content: start;
    align-items: center;
    border-right: 1px solid #ebecf0;
    padding: 20px;
    width: 50%;
    padding-bottom: 0px;
  }
  
  .salary-body-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 50%;
    padding-bottom: 0px;
    position: relative;
    top: -10px;
  }
  
  .salary-breakup-title-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-weight: 600;
    color: #172b4d;
  }
  
  .salary-breakup-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-weight: 600;
    color: #172b4d;
  }
  
  .salary-body-11 {
    border-right: 1px solid #ebecf0;
    width: 50%;
    max-height: 500px;
    min-height: 400px;
    overflow: auto;
    padding: 20px;
  }
  
  .salary-body-12 {
    border-right: 1px solid #ebecf0;
    width: 50%;
    max-height: 500px;
    min-height: 400px;
    overflow: auto;
    padding: 20px;
  }
  
  .salary-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 1px solid #e3ebfd;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .my-salary-drawer-body {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 430px;
    width: 75%;
    margin: 0 auto;
  }
  
  .my-salary-drawer-body-1 {
    width: 100%;
  }
  
  .salary-details-drawer {
    max-height: 400px;
    min-height: 400px;
    overflow: auto;
    padding: 20px;
  }
  
  .my-tax-declaration-bare-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    flex-direction: column;
  }
  
  .tax-declaration-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #172b4d;
  }
  
  .tax-declaration-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #757575;
  }
  
  .new-declaration-body {
    // display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 650px;
    width: 100%;
    flex-direction: column;
    overflow: auto;
    margin: 15px;
    .modal-component-leave-rules_width {
      width: 100%;
    }
  }
  
  .new-declaration-body-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    border: 1px solid #ebecf0;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
  
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  
    color: #172b4d;
  }
  .plan-name {
    font-weight: 500px;
    font-size: 16px;
  }
  .new-declaration-body-subtitle-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #ebecf0;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    padding: 10px;
    // border-bottom: none;
    padding-left: 30px;
    padding-right: 30px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .new-declaration-body-subtitle-out {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #ebecf0;
    border-top: none;
    border-radius: 6px;
    height: 93px;
    box-sizing: border-box;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    width: 100%;
    padding: 30px;
    margin-bottom: 20px;
  }
  .new-declaration-body-subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #ebecf0;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    padding: 30px;
    margin-bottom: 20px;
  }
  
  .new-declaration-body-subtitle-body {
    display: flex;
    justify-content: center;
    align-items: self-start;
    flex-direction: column;
  }
  
  .new-declaration-body-subtitle-body-header {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    // line-height: 28px;
    color: #172b4d;
    margin-bottom: 10px;
  }
  
  .new-declaration-body-subtitle-body-body {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #172b4d;
  }
  
  .modal-style-rented {
    width: 90vw;
    height: 90vh;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .modal-container-style-edit-profile {
    width: 80vw;
    height: 90vh;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    .active-button {
      width: 113px;
      height: 35px;
      background: #f8f8f8;
      border-radius: 3px;
    }
  
    &:focus {
      outline: none !important;
    }
  }
  
  .modal-container-style-preview {
    width: 60vw;
    height: 90vh;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
    .active-button {
      width: 113px;
      height: 35px;
      background: #f8f8f8;
      border-radius: 3px;
    }
  
    &:focus {
      outline: none !important;
    }
  }
  
  .form_fields_parent {
    position: relative;
    margin-bottom: 50px;
  }
  
  .form_fields_parent .more-option-cancel {
    position: absolute;
    top: -17px;
    right: 5px;
  }
  
  .timeline-container {
    font-family: "Segoe UI Web (West European)", sans-serif;
    max-width: 800px;
    margin: 0 auto;
    border-left: 1px dashed #bdbdbd;
    padding-left: 25px;
    margin-left: 130px;
  }
  
  .timeline-container h2 {
    font-size: 32px;
    margin: 0;
    color: #00272b;
    border-bottom: 1px solid #eee;
  }
  .timeline-container h3 {
    font-family: "Segoe UI Web (West European)", cursive;
    font-size: 20px;
    margin: 0;
    color: #00272b;
    margin-bottom: 10px;
    font-weight: 800;
  }
  .timeline-container .event-title {
    padding: 20px;
    margin: 20px 0;
    margin-top: -5px;
    border-radius: 4px;
    line-height: 2;
    cursor: pointer;
    position: relative;
    transition: all 500ms;
  }
  
  .timeline-container .event-title::before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    left: -50px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    transition: all 500ms;
  }
  .timeline-container .event {
    padding: 25px;
    background-color: #f5f7ff;
    margin: 10px 0;
    margin-top: -30px;
    border-radius: 4px;
    line-height: 2;
    cursor: pointer;
    position: relative;
    transition: all 500ms;
  }
  
  .timeline-container .event::before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    left: -79px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    transition: all 500ms;
  }
  
  .timeline-container .event-title .event-date {
    position: absolute;
    left: -150px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 400px;
  }
  
  .event-date {
    display: inline-flex;
    align-items: center;
  }
  
  @media (max-width: 700px) {
    .timeline-container {
      max-width: 100%;
      margin-left: 8px;
    }
  
    .timeline-container .event-title .event-date {
      position: static;
      margin-bottom: -20px;
    }
  }
  
  #leave-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  #leave-table td,
  #leave-table th {
    border: 1px solid #ddd;
    padding: 12px;
    min-width: 165px;
  }
  
  #leave-table th {
    text-align: left;
    color: black;
    background-color: #f5f5f5;
  }
  
  .sidebyside tr:nth-child(even) {
    background-color: #f5f5f5;
  }
  
  .modal-component-engages {
    width: 461px;
    height: 438px;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  .modal-component-engage-liked-by {
    width: 461px;
    height: 460px;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    .container-list {
      width: 100%;
      height: 380px;
      padding: 10px 30px 20px;
    }
    &:focus {
      outline: none !important;
    }
  }
  
  .appover-details {
    display: grid;
    grid-template-columns: 25% 25% 50%;
  }
  
  .modal-component-new-ticket {
    width: 461px;
    height: 90vh;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    .container-list {
      width: 100%;
      height: 380px;
      padding: 10px 30px 20px;
    }
    &:focus {
      outline: none !important;
    }
  }
  
  .navigationArea-1 {
    width: 250px;
    height: calc(100vh - 120px);
    border-right: 1px solid #ebecf0;
  
    .link-navigateList {
      width: 100%;
      text-decoration: none;
      display: flex;
      padding: 0px 12px 0px 30px !important;
  
      .navigationList {
        width: 100%;
        height: 50px;
      }
  
      .collapse-button {
        width: 100%;
        padding: 0px 12px;
        height: auto;
      }
    }
  }
  
  .modal-component-faq {
    width: 461px;
    height: 438px;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    .container-list {
      width: 100%;
      height: 380px;
      padding: 10px 30px 20px;
      @include overflowAuto();
    }
    &:focus {
      outline: none !important;
    }
  }
  .modal-component-tab {
    width: 461px;
    // min-height: 471px;
    height: auto;
    max-height: 551px;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .roster-create {
    .roster-create-value {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: repeat(3, minmax(200px, 429px));
    }
  }
  .w-auto-400 {
    max-width: 400px;
    width: auto;
  }
  
  .table-height {
    height: calc(100% - 16px);
  }
  
  .workflow-header {
    display: grid;
    grid-template-columns: 2fr 8fr 2fr;
    align-items: center;
  }
  
  .selected-workflow {
    border: 2px solid #335aff !important;
    color: #335aff !important;
    background-color: #fff !important;
  }
  
  .list-popover-app {
    width: 250px;
    background-color: #ffffff;
    .list-item-app {
      width: 100%;
      cursor: pointer;
      .list-item-icon-app {
        min-width: 2rem !important;
        color: inherit;
      }
    }
  }
  
  .imagecolor {
    width: 46px;
    height: 46px;
    border-radius: 5px;
    margin-right: 15px;
  }
  .list-item-app > .imagecolor:hover {
    background-color: transparent;
  }
  
  .bankListHead {
    font-size: 14px !important;
  
    letter-spacing: 0.15px !important;
    color: #172b4d;
    font-weight: 400 !important;
    text-align: left;
    padding: 1px !important;
  }
  .bankList {
    font-size: 12px !important;
    color: #757575 !important;
    text-align: left;
    padding: 1px !important;
    font-weight: 400 !important;
  }
  .roster-title {
    width: 100%;
    // height: 114px;
    border: 1px solid #ebecf0;
    border-radius: 6px;
    display: grid;
    grid-template-columns: 121px auto;
    .roster-right-area {
      display: grid;
      width: 100%;
      height: 100%;
      padding: 34px;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 30px;
    }
  }
  
  .roster-container {
    width: 100%;
    height: calc(100vh - 220px);
  }
  .list-popover-roster {
    width: 400px;
    height: 303px;
    background-color: #ffffff;
    cursor: pointer;
  }
  
  /* antd progress-bar */
  .progress {
    border: 0;
    height: 40px;
    border-radius: 20px;
  }
  progress::-webkit-progress-bar {
    border: 0;
    height: 40px;
    border-radius: 5px;
  }
  progress::-webkit-progress-value {
    border: 0;
    height: 40px;
    border-radius: 5px;
  }
  progress::-moz-progress-bar {
    border: 0;
    height: 40px;
    border-radius: 5px;
  }
  .ant-progress-bg {
    border-radius: 5px !important;
  }
  
  .ant-progress-inner {
    border-radius: 5px !important;
  }
  
  .modal-component-delete {
    width: 505px;
    height: 224px;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  .top-70px {
    padding-top: 70px !important;
  }
  .modal-component-confirm {
    width: 505px;
    // height: 200px;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  .modal-component-display {
    width: 90%;
    // height: 200px;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  .listItemAvatar {
    .MuiBadge-colorPrimary {
      color: rgb(2, 179, 2) !important;
      background-color: rgb(2, 179, 2) !important;
      top: 50%;
      right: 30%;
    }
    .MuiBadge-colorSecondary {
      color: gray !important;
      background-color: gray !important;
      top: 50%;
      right: 30%;
    }
  }
  // .ck.ck-editor {
  //   display: flex;
  //   flex-direction: column-reverse;
  //   width: -webkit-fill-available !important;
  // }
  // .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
  //   top: -150% !important;
  //   bottom: auto;
  // }
  // .ck-editor__editable {
  //   min-height: 100px;
  // }
  .top-confirm {
    padding-top: 30vh !important;
  }
  .displayModal {
    padding-top: 10vh;
  }
  .imageViewer {
    cursor: zoom-in;
  }
  
  .modal-create-Post {
    width: 600px;
    min-height: 331px;
    max-height: 500px;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  }
  
  .icon-tab {
    color: "#BDBDBD";
  }
  colorPrimary .roster-location-popover {
    width: 460px;
    height: 255px;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12), 0px 3px 14px rgba(0, 0, 0, 0.1),
      0px 5px 5px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    padding: 20px 30px 30px;
  }
  
  .header-color-0 {
    margin-bottom: 20px;
    text-decoration: none;
    border-radius: 0px;
    &:hover {
      background-color: rgba(0, 205, 55, 0.05);
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .header-color-1 {
    margin-bottom: 20px;
    text-decoration: none;
    border-radius: 0px;
    &:hover {
      background-color: rgba(250, 200, 0, 0.05);
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .header-color-2 {
    margin-bottom: 20px;
    text-decoration: none;
    border-radius: 0px;
    &:hover {
      background-color: rgba(221, 79, 36, 0.05);
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .font-app-icon {
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
      sans-serif !important;
    font-weight: 500 !important;
  }
  
  .MuiBadge-colorSecondary {
    color: "yellow" !important;
    background-color: "yellow" !important;
  }
  
  .transfer-modal {
    width: 460px;
    height: 85.28vh;
    background: #ffffff;
    position: relative;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none;
    }
  }
  
  .margin-0 {
    margin: 0 !important;
  }
  
  .help-head {
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: white;
  }
  
  .help-text {
    font-weight: 400;
    font-size: 14px;
    color: #bdbdbd;
    margin-top: 5px;
  }
  
  .gyde-class > * {
    fill: #172b4d;
  }
  .gyde-class > *:hover {
    fill: #335aff;
  }
  
  .modal-container-style-leaveType {
    position: relative;
    background-color: white;
    width: 460px;
    height: auto;
    max-height: 571px;
    border-radius: 5px;
    &:focus {
      outline: none !important;
    }
  }
  
  .content-wrap22 {
    margin: 150px 0px 0px 0px;
    display: block;
    width: 100vw;
  }
  
  .thank_wrap {
    background-color: #eee;
    padding: 40px 20px;
    text-align: center;
    width: 600px;
    border: 1px solid #ddd;
    margin: auto;
    font-size: 16px;
  }
  
  .user-form-container {
    width: 100vw;
    height: calc(100vh - 60px);
    .custom-stepper_1 {
      width: 100%;
      height: 60px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
      .inside-custom-stepper_1 {
        width: 60%;
        min-width: 380px;
      }
    }
    .emp-create_1 {
      height: calc(100% - 126px) !important;
      .emp-create-from-list_1 {
        @include overflowAuto();
        width: 100%;
        .emp-create-from_1 {
          flex-wrap: wrap;
          list-style: none;
          li:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .emp-create_2 {
      height: calc(100% - 180px) ;
  
      .emp-create-from-list_1 {
        @include overflowAuto();
        width: 100%;
  
        .emp-create-from_1 {
          flex-wrap: wrap;
          list-style: none;
  
          li:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .create-user-footer_1 {
      width: 100vw;
      height: 66px;
      z-index: 2;
      background-color: #ffffff;
      .button-container {
        width: 100%;
        height: 65px;
        padding: "0px 20px";
      }
    }
  }
  .emp-scroll {
    background-color: #f5f5f5;
  }
  .header-box {
    box-shadow: 2px 1px 1px #dfdfdf;
    width: 100%;
    // height:49px !important;
  }
  
  .header-tabs .PrivateTabIndicator-colorSecondary-21 {
    background-color: white !important;
    // height: 20% !important;
    // margin-bottom: 2vh;
    color: white !important;
    // margin-top: 2vh;
    // padding-right: 2vw;
    // padding-left: 2vw;
    // border-radius: 10px;
  }
  
  .css-17yibpu-MuiTab-wrapper {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: flex-start !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .announcementBullete {
    width: 34px;
    height: 34px;
    background: rgba(250, 200, 0, 0.05);
    border: 2px solid rgba(243, 175, 0, 0.1);
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  
  .updates__bugs {
    display: grid;
    grid-template-columns: 20% 80%;
    margin: 25px 0px 25px 0px;
  }
  .bugs__title {
    height: 200px;
    text-align: center;
    padding: 20px;
  }
  .b_info {
    height: auto;
    min-height: 130px;
    border-left: 0.0625rem solid #ebecf0;
    // border-radius: 6px;
    padding: 5px 20px 35px 35px;
    margin: 15px;
  }
  
  .b_info p {
    padding: 0px 5px;
    border-radius: 5px;
    color: #757575;
  }
  
  // .updates__container {
  //   margin-right: 100px;
  //   margin-left: 10px;
  // }
  
  .img__wrap {
    position: relative;
    // border: 1px solid #ddd; /* Gray border */
    // border-radius: 4px; /* Rounded border */
    // padding: 5px; /* Some padding */
    // width: 141px; /* Set a small width */
    // height: 67px;
  }
  .img__description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    color: rgba(255, 255, 255, 1);
    visibility: hidden;
    opacity: 0;
  }
  
  .img__wrap:hover .img__description {
    visibility: visible;
    opacity: 0.8;
    cursor: pointer;
    color: white;
    width: 126px;
    height: 45px;
    border-radius: 0;
    text-align: center;
    padding-top: 25px;
    margin-left: 7px;
  }
  
  .img__wrap img {
    border: 1px solid #ddd; /* Gray border */
    border-radius: 4px; /* Rounded border */
    padding: 5px; /* Some padding */
    width: 141px; /* Set a small width */
    height: 67px;
  }
  // .imgimg img:hover {
  //   box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  //   cursor: pointer;
  // }
  
  .info__container {
    overflow-x: scroll;
    max-height: 80vh;
    overflow-x: hidden;
  }
  
  .title__info {
    padding: 10px;
    width: 80vw;
    margin: 0 auto;
  }
  .announcement-header {
    font-size: "14px";
  }
  
  .npsEmoji {
    width: 52px;
    height: 52px;
    border-radius: 50px;
    background-color: rgba(244, 116, 108, 0.2);
  }
  
  .header-background {
    background: #f0f0f0;
    //padding: 8px 8px 8px 15px;
    padding: 13px;
    border-radius: 4px 4px 0px 0px;
  }
  
  /* root element for all positioning */
  .round-input-container {
    display: inline-block;
    position: relative;
    margin: 10px 0;
  }
  
  /* container for borders and the label text */
  /* position it behind the input-element at full  */
  /* height and width & set it as flex-container */
  
  .round-input-decorator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    background: #fbfbfb;
    border-radius: 5px;
  }
  
  .round-input-label-text {
    font-size: 12px;
    color: #757575;
    padding: 0 4px;
    transform: translate(0, -6px);
    white-space: nowrap;
  
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .round-input-border-left {
    flex: 0 0 4px;
    box-sizing: border-box;
    height: 100%;
    border-radius: 10px 0 0 10px;
    border: 1px solid #fbfbfb;
    border-right: none;
  }
  .round-input-border-right {
    flex: 1 0 50px;
    border-radius: 0 10px 10px 0;
    border: 1px solid #fbfbfb;
    border-left: none;
  }
  
  /* Position relative puts the element in front */
  .round-input {
    position: relative;
    font-size: 14px;
    padding: 13px 20px 13px 9px;
    min-width: 250px;
    border: none;
    background: none;
    border-radius: 10px;
    border-bottom: 1px solid #fbfbfb;
  }
  
  .round-input-gutter-s {
    position: relative;
    font-size: 14px;
    padding: 10px 20px 10px 9px;
    min-width: 250px;
    border: none;
    background: none;
    border-radius: 10px;
    border-bottom: 1px solid #fbfbfb;
  }
  
  .grey-border {
    border: 1px solid #efefef;
    border-radius: 4px;
  }
  
  .p-1 {
    padding: 1.2rem;
  }
  .box-40-100 {
    border: 1px solid #ebecf0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  }
  /* ssr popup */
  .firstpopup-box {
    .ssr-text-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      //margin-bottom: 1px;
      p {
        margin-block-start: 0 !important;
        margin-block-end: 0 !important;
      }
    }
    .calendar-status {
      display: block;
  
      /*Overlapping on bug BUG-4141
      occuring due to the below line.
      */
      // position: absolute;
      // right: 0;
    }
  }
  
  .firstpopup-box .calendar-front-time {
    display: none;
  }
  .text-box1 > span > p:nth-child(n) {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  
  .dynamic-show-more {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  
  .dynamic-show-more > p {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .firstpopup-box > p {
    margin-top: 0px;
    margin-bottom: 0.8px;
  }
  .firstpopup-box .ssr-icon-helper .tooltip {
    visibility: hidden;
  }
  .mui-autocomplete > div {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-height: 34px !important;
    border: 0.5px solid #ebecf0 !important;
  }
  .emptyFields {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0;
    text-align: center;
    margin-top: -80px;
  }
  
  .leave-table-scroll {
    overflow: auto;
    white-space: nowrap;
  }
  
  .special-announce {
    height: 54px;
    background: rgba(243, 175, 0, 0.05);
    border: 1px solid rgba(243, 175, 0, 0.2);
    border-radius: 4px;
    padding: 9px;
    margin-left: 10px;
  }
  
  .leave-subheading {
    font-size: 14;
    font-weight: 450 !important;
  
    padding-top: 30px;
    padding-bottom: 20px;
  }
  
  .leave-subheading-4 {
    font-size: 14;
    font-weight: 500 !important;
  
    padding-top: 30px;
    padding-bottom: 20px;
  }
  
  .leave-subheading-2 {
    font-size: 14;
    font-weight: bold !important;
  
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .leave-subheading-3 {
    font-size: 14;
  
    color: #757575 !important;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  
  .pd-10-grey {
    font-size: 14;
  
    color: #757575 !important;
    padding: 10px;
  }
  
  .pd-10-grey-bold {
    font-size: 14;
  
    color: #757575 !important;
    padding: 10px;
    font-weight: bold !important;
  }
  
  .leave-border-box {
    border-radius: 2px;
    border: 1px #c0c0c0;
  }
  
  .w-100-m-5 {
    width: 100%;
    margin: 5px;
  }
  
  .leave_input_box_small {
    height: 20;
  }
  
  .border-1-grey {
    border: 1px solid #f0f0f0;
    padding: 10px;
    // margin-bottom:2px;
    border-radius: 6px;
  }
  .mb-10 {
    margin-bottom: 10px !important;
  }
  .mb-15 {
    margin-bottom: 15px !important;
  }
  .mb-30 {
    margin-bottom: 15px !important;
  }
  .mb-5 {
    margin-bottom: 5px !important;
  }
  
  .flex-container {
    display: flex;
  }
  
  .leave-rule-number-input {
    width: 50px;
    margin-left: 10;
  }
  
  .mr-5 {
    margin-right: 5px !important;
  }
  
  .pt-20 {
    padding-top: 30px;
  }
  
  .lh-15 {
    line-height: 1.5;
    margin-top: 10px;
  }
  
  .create-leave-btn {
    position: absolute;
    right: 20px;
    margin: 20px;
    font-weight: bold;
  }
  
  .lh-15 {
    line-height: 35.23px;
  }
  
  .leave-checkbox-label {
    font-style: normal !important;
    line-height: 20px !important;
    color: #757575 !important;
    margin-left: -10px;
  }
  
  .leave-rule-input-box {
    box-sizing: border-box;
    // position: absolute;
    height: 13px !important;
    // left: 47.57%;
    // right: 50.14%;
    // top: 515px;
  
    background: #ffffff;
    border: 1px solid #ebecf0;
    border-radius: 3px;
  }
  .attendance-dropdown-box {
    width: 62% !important;
    margin-right: 8px;
    padding-top: 12px;
  }
  
  .request-popup-border {
    border: 1px solid #ebecf0;
    border-radius: 5px;
  }
  
  .align-radio {
    margin-left: -10px;
  }
  
  .mtb--10 {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  
  .mt--5 {
    margin-top: -5px !important;
  }
  
  .mt--4 {
    margin-top: -4px !important;
  }
  
  .admin-tableheight2 {
    height: 75vh;
  }
  
  #brandingLogo {
    display: none !important;
  }
  
  .personDescription {
    background-color: #eeeeee;
    padding: 6px;
    //width: 100px;
    border-radius: 6px;
    margin-top: -4px;
  }
  
  .tooltipBox {
    background-color: #666666;
    color: white;
    border-radius: 4px;
    padding: 4px;
  }
  
  .orgPhoto {
    border-radius: 50%;
  }
  
  #widgetsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: white;
    box-sizing: border-box;
    width: 100%;
  }
  
  .chartDiv {
    width: 100%;
    box-sizing: content-box;
    margin: 8px;
    border-radius: 10px;
    background: white;
    height: 95px;
  }
  .pl-10 {
    padding-left: 10px !important;
  }
  
  .custompic .input-field {
    height: auto;
  }
  
  .info-calendar-msg {
    margin-left: 3;
    padding: 5;
    color: "black";
    border: 5;
    background-color: "#fadf99";
  }
  //
  .grid-dashboard-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
    padding: 10px;
  }
  
  .grid-dashboard-container > div {
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
  }
  
  .item1 {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
  }
  
  .card-shadow {
    min-height: 100px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05) !important;
    border-radius: 6px !important;
  }
  
  .dragButton {
  }
  
  .dragAvatar {
    font-size: 10px !important;
  }
  .dragText {
    height: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    align-items: center;
    color: #172b4d;
  }
  
  .btn-arrow-right {
    position: relative;
    margin: 10px;
    padding-left: 30px;
    padding-right: 14px;
    padding-top: 2.8px;
    padding-bottom: 2.8px;
  
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    //display: flex;
    align-items: center;
    border: none;
    height: 34px;
  }
  
  .arrow-tab-selected {
    background: #172b4d;
    color: #ffffff;
  }
  
  .arrow-tab-selected:before,
  .arrow-tab-selected:after,
  .arrow-tab-unselected:before,
  .arrow-tab-unselected:after {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
  }
  
  .arrow-tab-selected:before,
  .arrow-tab-unselected:before {
    left: -11px;
  }
  
  .arrow-tab-selected::after {
    //right: -11px;
    right: -34px;
    border-width: 17px;
    top: -1px;
    border-color: transparent transparent transparent #172b4d;
    border-style: solid;
  }
  
  .arrow-tab-unselected::after {
    right: -34px;
    border-width: 17px;
    top: -1px;
    border-color: transparent transparent transparent #f0f0f0;
    border-style: solid;
  }
  
  .arrow-tab-unselected::before {
    right: -66px;
    border-width: 17px;
    top: -1px;
    left: -1px;
    border-color: transparent transparent transparent white;
    border-style: solid;
  }
  
  .arrow-tab-selected::before {
    right: -66px;
    border-width: 17px;
    top: -1px;
    left: -1px;
    border-color: transparent transparent transparent white;
    border-style: solid;
  }
  
  .btn-arrow-right:nth-child(1)::before {
    border-width: 0px !important;
  }
  
  .btn-arrow-right:nth-child(1) {
    margin-left: 0px !important;
  }
  
  .arrow-tab-selected:after {
    z-index: 1;
  }
  
  .arrow-tab-selected:before {
    //background-color: white;
  }
  
  .borderTable {
    border-spacing: 0px 15px !important;
    border-collapse: separate !important;
    width: 99% !important;
    margin: auto;
  }
  
  .borderTableRow {
    box-shadow: 0px 0px 1px 1px #f0f0f0;
    border-radius: 6px;
  }
  
  .sent {
    background-color: rgba(51, 90, 255, 0.05);
    color: #4f44e0;
    border: none;
    border-radius: 3px;
    font-size: 12px;
    height: 30px;
    width: 75px;
  }
  .pending {
    background-color: rgba(255, 174, 9, 0.1);
    color: #ffae09;
    border: none;
    border-radius: 3px;
    font-size: 12px;
    height: 30px;
    width: 75px;
  }
  
  .backlist {
    background-color: rgba(117, 117, 117, 0.1);
    color: #172b4d;
    border: none;
    border-radius: 3px;
    font-size: 12px;
    height: 30px;
    width: 75px;
  }
  
  .withdraw {
    background-color: rgba(221, 79, 36, 0.1);
    color: #de350b;
    border: none;
    border-radius: 3px;
    font-size: 12px;
    height: 30px;
    width: 75px;
    font-weight: 400;
  }
  
  .config-box-onboarding {
    width: 35% !important;
    border: 1px solid #ebecf0;
    border-radius: 6px;
  }
  
  // .config-box-onboarding-desc {
  //   width: 65% !important;
  //   border: 1px solid #ebecf0;
  //   border-radius: 6px;
  // }
  
  .pl-10 {
    padding-left: 10px !important;
  }
  
  .config-box-onboarding {
    width: 35% !important;
    border: 1px solid #ebecf0;
    border-radius: 6px;
  }
  
  
  .align-mid-center {
    position: relative !important;
    top: 48% !important;
    left: 48% !important;
  }
  
  .modal-component-loader-leave-rules-2 {
    width: 600px;
    height: 600px;
    background: #ffffff;
  }
  
  .max-width-joinee-form {
    max-width: min-content !important;
  }
  .modal-container-style-leave-preview {
    width: 1200px;
    height: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    &:focus {
      outline: none !important;
    }
  
    .container-padding {
      padding: 30px 30px;
    }
  
    .container-button {
      height: 65px;
      width: 100%;
      border-top: 0.0625rem solid #ebecf0;
      padding: 0rem 1rem;
    }
  }
  
  .shadow {
    box-shadow: 0 2px 8px hsla(0, 0%, 0%, 0.08);
  }
  .steppolicy {
    width: 60vw;
    margin: 20px auto;
  }
  .stepheight {
    clear: both;
    height: calc(100vh - 270px);
    padding-top: 20px;
  }
  
  .shift-anchor-tag {
    text-decoration: none;
    cursor: pointer;
    display: flex;
  
    &:hover {
      text-decoration: underline;
      color: #335aff;
    }
  }
  
  /***Added new css **/
  .column-gap-2 {
    grid-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  
  .row-gap-02 {
    row-gap: 0.3rem !important;
  }
  
  .w-100_pt-3_pl-5 {
    width: 90% !important;
    padding-left: 5%;
    padding-top: 3%;
  }
  
  .message-item{
  
    .any-mark , .my-mark {
      padding:3px;
      border-radius: 10px;
    }
  
    .any-mark{
      background-color: inherit !important;
      color: #335AFF !important;
    }
  
    .any-mark:hover {
      background-color: rgba(192, 192, 153, 0.9) !important;
      cursor: pointer;
    }
  
    .my-mark{
      color: white !important;
      background-color: #78c9e9 !important;
    }
  
    .my-mark:hover{
      // color: white !important;
      background-color: #335AFF !important;
      cursor: pointer;
    }
  }
  
  .zIndex-99999{
    z-index: 99999 !important;
  }
  
  .highlight-1{
    background-color: #E6ECFF !important;
  }
  
  .highlight-2{
    background-color: #FFCCCC !important;
  }
  
  .highlight-3{
    background-color: #E9FDF8 !important;
  }
  
  .highlight-4{
    background-color: #FFFAE6 !important;
  }
  
  .highlight-5{
    background-color: #FFF1E6 !important;
  }
  
  .height-10{
    height: 50px !important;
  }
  
  .preview-header{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    position: absolute;
    top:0%;
  }
  
  .preview-header > svg{
    border-radius: 50%;
    background-color: rgb(23,43,77,0.3);
    color:white;
    margin-inline: 0.3rem;
    margin-block:0.3rem;
    padding: 0.3rem;
    transition: transform ease-in-out 0.3s;
  }
  
  .preview-header > svg:hover{
    cursor:pointer;
    transform: scale(1.1);
  
  }
  .preview-link{
    position: relative;
  }
  
  /* Hide scrollbar for Chrome */
  .preview-link::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .preview-link {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .feed-image-object-contain img {
    object-fit: contain !important;
    width: 36px !important;
    background: #e3e3e3;
  }
  
  .modified-avatar-css{
    .MuiAvatarGroup-avatar  {
      height: 30px !important;
      width: 30px !important;
      font-size: 15px !important;
      font-weight: 360 !important;
    }
  }
  
  
  .modazindex{
    z-index: 9 !important;
  }
  .rc-time-picker-input{
    cursor: pointer !important;
    height: 50px !important;
    font-size: 14px !important;
    background-image: url(https://cdn-icons-png.flaticon.com/512/3917/3917267.png) !important;
    background-size: 10% !important;
    background-repeat: no-repeat !important;
    background-position: 94% !important;
  
  }
  .rc-time-picker-panel-input-wrap{
    padding: 14px 7px !important;
  }
  .rc-time-picker-clear{
   display: none !important;
  }
  .rc-time-picker-clear-icon:after{
    font-size: 15px !important;
    color: #4a4a4a !important;
  }
  
  .textmaininput {
    position: absolute;
    z-index: 9;
    font-size: 11px;
    background: #fff;
    top: -8px;
    left: 10px;
    color: #a9a9a9;
    padding: 0 5px;
  }
  
  .leave-clubbing-box {
    display: flex;
    align-items: center;
    padding: 0px 8px;
    background: #E6ECFF;
    border-radius: 4px;
    width:auto;
    height:25px;
    margin-inline:0.5rem;
    margin-block:0rem;
    font-size: clamp(0.5rem,0.8vw,0.8rem);
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  

  .edm-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    color: #172B4D;
  }

  .title20px {
    font-weight: var(--font)!important;
    font-size: 20px !important;
    line-height: 19px!important;
    color: var(--color) !important;
  }

  .title18px {
    font-weight: var(--font)!important;
    font-size: 18px !important;
    line-height: 19px!important;
    color: var(--color) !important;
  }
  
  .title16px {
    font-weight: var(--font) !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: var(--color) !important;
  }
  
  .text14px {
    font-weight: var(--font) !important;
    font-size: 14px  !important;
    line-height: 19px !important;
    color: var(--color) !important;
  }
  
  .text12px {
    font-weight: var(--font) !important;
    font-size: 12px !important;
    line-height: 19px !important;
    color: var(--color) !important;
  }
  
  .text24px {
    font-weight: var(--font) !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: var(--color) !important;
  }
  
  
  
  
  .icon-shadow-popups{
    display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 10px;
  
  width: 40px;
  height: 40px;
  
  background: #F6FAFF;
  border-radius: 200px;
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  }
  
  
  // time line component css code
  
  .timeline_grid {
    border-radius: 8px;
    background-color: #fff;
    padding: 24px;
    overflow: auto;
    height: calc(100vh - 110px);
  }
  
  .timeline_date_box {
    background: #4C70FF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    color: #fff;
    width: 100px;
    height: 26px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .timeline_message {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 13px;
    box-shadow: 0px 2px 10px #eee;
    color: #615E69;
    height: 76px;
    overflow: hidden;
  
    .old-value , .new-value ,   .boolean-value-red , .boolean-value-blue{
      font-size: 16px;
      line-height: 100%;
    }
  
    .old-value{
  
      color: #172B4D;
    }
  
    .new-value{
  
      color: #4C70FF;
    }
  
    .boolean-value-red{
  
      color:#DD4F24;
    }
  
    .boolean-value-blue{
  
      color: #4C70FF;
  
    }
  
    .timeline_updatedby {
      font-weight: 400;
      font-size: 12px;
      margin-top: 21px;
      line-height: 100%;
      color: #615E69;
  
      .timeline_emp_name {
        color: #615e69d3;
      }
    }
  
  
  }
  
  .custom-dropdown {
    .custom-dropdown-placeholder {
      position: absolute;
      z-index: 1;
      left: 8px;
      top: -8px;
    }
  }
  
  .overview__container__bg {
    width: 100% !important;
    height: 100vh !important;
    overflow: scroll;
    font-style: normal;
    padding: 10px !important;
    background-color: #fafbfc;
  }
  .hidden-child {
    visibility:hidden;
  }

  .table-list-row:hover .hidden-child {
    visibility: visible;
  }
  .table-list-row:hover .table-row-text {
    font-weight: bold;
  }
  .submit-btn-disabled {
      display: inline-flex;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      outline: 0px;
      border: 0px;
      margin: 0px 0px 0px 20px;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 500;
      font-size: 0.9375rem;
      line-height: 1.125;
      letter-spacing: 0px;
      text-transform: capitalize;
      color: rgb(255, 255, 255);
      min-width: 64px;
      padding: 8px 22px;
      border-radius: 4px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: rgb(51, 90, 255);
      box-shadow: none;
      height: 35px;
  }
  .user-data-name-admin {
  .basic-multi-select {
    height: 35px;
    width: 205px;
  }
  
    display: "flex";
    align-items: "center";
    margin-bottom: "40px";
    margin-left: "6px";
  
  }
  
  .user-data-admin {
    font-family: "Montserrat";
    font-style: "normal";
    font-size: "15px";
  }
  .leave-modal-container-padding{
    padding:30px 30px 3px 30px;
  }
  
  .leave-modal-main-heading{
    font-family: "Segoe UI";
    font-weight: 400;
  }
  .leave-modal-grid {
    display: grid;
   grid-auto-flow :row;
   gap: 1;
  margin-left: 20px;
  }
  
  .leave-modal-details-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(173px, 1fr));
    gap: 1.5rem;
    margin-top: 16px;
    margin-bottom:24px;
  }
  
  .leave-modal-details-item-container{
    display:flex;
    flex-direction:column;
    gap:0.5rem;
  }
  
  
  .expense-list-row .expense-name-text{
    text-transform: capitalize;
    cursor: pointer;
    font-weight: 400;
    min-width: 140px;
    white-space: nowrap;
  }
  
   .expense-list-row:hover p{
    font-weight: 600 !important;
  }
  
  .confirm-message-heading-one {
    color:  #30323E !important;
    font-size: 20px !important;
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    text-align: center !important;
  }
  
  .confirm-message-heading-two {
    color: #A5A3A9 !important;
    text-align: center !important;
    font-size: 14px !important;
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
  }

  .g-20{
    gap: 20px;
  }

  .g-10{
    gap: 10px;
  }
  .g-5{
    gap: 5px;
  }

  .MuiFormLabel-asterisk{
    color:red !important
  }

  .container__white__card{
    background-color: white;
    padding: 20px;
    // margin-top: 12px;
    height: min-content;

  }

  .text__align__center {
    text-align: center !important;
  }

  //create objective end row
  .end-row {
    display:flex;
    justify-content: space-between;
    margin-top:2rem;
    align-items: center;

    .cancel{
      background: rgb(235, 236, 240) !important;
      border: none;
      border-radius: 4px;
      margin-left:10px;
    
      color: rgb(23, 43, 77) !important;
      text-transform: capitalize;
      cursor: pointer;
      font-size: 14px;
      text-decoration: none;
      z-index: 1;
      padding: 8px 22px;
      font-weight: 500;
      height: 35px;
      letter-spacing: 0.02857em;
      min-width: 64px;
      outline: none !important;
      white-space: nowrap;
      font-family: Roboto, sans-serif;
    }
    
    .save{
      border-radius: 4px;
      border: none;
      transition: 0.2s ease-out;
      color: #fff;
      margin: 0;
      word-wrap: break-word;
      text-transform: capitalize;
      background: rgb(51, 90, 255);
      position: relative;
      cursor: pointer;
      font-size: 14px;
      text-decoration: none;
      z-index: 1;
      padding: 8px 22px;
      font-weight: 500;
      outline: none !important;
      height: 35px;
      letter-spacing: 0.02857em;
      font-family: Roboto, sans-serif;
      min-width: 64px;
    }
  }
  
  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: rgb(79, 79, 79);
  }
  
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #172b4d;
    text-transform: capitalize;
  }


.feedback__container .continuous__feedback__list {
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.feedback__container .one__to__one__list {
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.feedback__container {
  width: 100%;
}

.feedback__container .appreciations__list {
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.form_error {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 13px;
  color: #ff0000;
  margin-top: 10px;
  margin-left: 5px;
}

.form_error p {
  margin: 0 !important;
  color: #ff0000;
}


.overlay {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(0,0,0,0.5); */
  background-color: #ffff;
  z-index: 999;
}

.overlay-component {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0);
  z-index: 999;
}
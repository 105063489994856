@charset "utf-8";
/* CSS Digvijay singh
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500&display=swap");

/* Reset & Basics */
html {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.00938em;
  color: #212121;
}
body {
  background: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.00938em;
  line-height: 1.5;
  margin: 0;
  clear: both;
  color: #172b4d;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.close_filee {
  position: absolute;
  width: 18px;
  height: 18px;
  line-height: 16px;
  right: -8px;
  top: -8px;
  background: #000000;
  border-radius: 50px;
  text-align: center;
}

table.dataTable thead th {
  background: #f2f2f2 !important;
  font-weight: bold !important;
  position: sticky;
  top: -20px !important;
  z-index: 1;
}

.perfor_left ul li {
  word-break: break-all !important;
}

/*sahud css 24-05-21*/
.top_tabs {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ebecf0;
  clear: both;
  position: relative;
  font-family: "Poppins", sans-serif !important;
}
.top_tabs h2 {
  position: absolute;
  left: 30px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 22px 0px 0px 0px;
}

.newmob-menu {
  display: none !important;
}
.top_tabs ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.top_tabs ul li {
  display: inline-block;
}

.top_tabs ul li a {
  margin: 0px 8px;
  /* font-family: Poppins, sans-serif; */
  text-decoration: none;
  font-weight: 500;
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0em;
  color: rgba(0, 0, 0, 0.85);
  text-transform: capitalize;
  padding: 20px 0px;
  border: solid transparent;
  border-width: 0 0 2px;
  height: 58px;
}

.top_tabs ul li a:hover,
.top_tabs ul li a.active {
  border-bottom: 2px solid #1266f1;
  color: #1266f1;
}

.right_con {
  justify-content: flex-end;
  padding: 0px;
  display: flex;
}

.navigationleft {
  width: 300px;
  border-right: 1px solid #ebecf0;
  height: 100vh;
  /* overflow: hidden; */
}

.css-ailz3k {
  width: calc(100vw - 250px);
}

.css-1yqw6cw {
  /*earlier it was 64px instead of 30*/
  padding: 20px 30px;
  width: calc(100vw - 210px);
  overflow-y: auto;
  scrollbar-width: thin;
  position: relative;
}

.sahudnew {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: calc(100vh - 250px);
}

.nav-pills {
  margin: 20px 10px 0 30px !important;
  overflow-y: auto;
  height: calc(100vh - 220px);
  flex-direction: inherit !important;
  display: block;
  padding-right: 10px;
  scrollbar-width: thin;
  overflow-x: hidden;
}

.nav-pills .nav-link {
  text-align: left;
  text-transform: capitalize;
  margin: 0px;
  /* font-family: Poppins, sans-serif; */
  font-size: 14px;
  letter-spacing: 0em;
  font-weight: 500;
  padding: 0.5rem 10px;
  background: transparent;
  color: rgba(0, 0, 0, 0.85);
  justify-content: flex-start;
  -webkit-box-align: center;
  height: 50px;
  line-height: 34px;
  white-space: nowrap;
  align-items: center;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link:hover,
.nav-pills .show > .nav-link {
  color: rgb(51, 90, 255);
  background: rgba(51, 90, 255, 0.05);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.heag_new {
  margin: 0px;
  /* font-family: Poppins, sans-serif; */
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.00938em;
  color: rgb(23, 43, 77);
}

.allcssmain {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 25px;
}

.bgne1 {
  background: #f0f8ff !important;
  padding: 15px;
  /* margin-right: 20px; */
  width: 70px;
}
.bgne2 {
  background: #fff6f3 !important;
  padding: 15px;
  /* margin-right: 20px; */
  width: 70px;
}
.bgne3 {
  background: #e9ffed !important;
  padding: 15px;
  /* margin-right: 20px; */
  width: 70px;
}
.bgne4 {
  background: #d4ffd3 !important;
  padding: 15px;
  /* margin-right: 20px; */
  width: 70px;
}
.bgne5 {
  background: #fcffd3 !important;
  padding: 15px;
  /* margin-right: 20px; */
  width: 70px;
}
.bgne6 {
  background: #fef5ff !important;
  padding: 15px;
  /* margin-right: 20px; */
  width: 70px;
}

.icon-cover {
  width: 3.926vh;
  height: 3.926vh;
  background: #f8f8f8;
  border-radius: 4px;
}
.rig_filternew {
  border-left: 1px solid #eee;
  background: #fcfcfc;
  z-index: 9;
  height: 100vh;
}
.posifil {
  position: absolute;
  top: 10px;
}
.main_filnew {
  position: relative;
  margin-bottom: 20px;
  clear: both;
}
#createCompetencyStructure .main_filnew {
  height: auto;
}
.filter-center {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  display: flex;
}
.boxwidth {
  width: 190px;
  margin-right: 15px;
  position: relative;
}

.boxwidthV2 {
  width: 100%;
  margin-right: 15px;
  position: relative;
}

.search-component {
  width: 100%;
  height: 35px;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  padding: 13px;
  cursor: pointer;
  color: #717382;
  font-size: revert;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #999;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}

#createCompetencyStructure .search-component {
  height: 40px;
}
#createCompetencyStructure .select2-container .select2-selection--multiple {
  min-height: 40px;
}

#createCompetencyStructure
  .select2-container--default
  .select2-selection--single {
  height: 40px;
}

#createCompetencyStructure
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 40px;
}

#createCompetencyStructure
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 40px;
}

.btn_cancel {
  background: rgb(235, 236, 240) !important;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  color: rgb(23, 43, 77) !important;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  z-index: 1;
  padding: 8px 22px;
  font-weight: 500;
  height: 35px;
  letter-spacing: 0.02857em;
  min-width: 64px;
  outline: none !important;
  white-space: nowrap;
  font-family: Roboto, sans-serif;
}

.btn_save {
  border-radius: 4px;
  border: none;
  transition: 0.2s ease-out;
  color: #fff;
  margin: 0;
  word-wrap: break-word;
  text-transform: capitalize;
  background: rgb(51, 90, 255);
  position: relative;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  z-index: 1;
  padding: 8px 22px;
  font-weight: 500;
  outline: none !important;
  height: 35px;
  letter-spacing: 0.02857em;
  font-family: Roboto, sans-serif;
  min-width: 64px;
}

.select-component {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
  font-size: 13px;
  font-weight: 400;
  color: #717382;
  border: 1px solid #d6d6d6;
  height: 35px !important;
  width: 100%;
  margin: 0px;
  padding: 0px 9px;
  display: inline-block;
  /* background: #fff url(../images/down-arrow.png) no-repeat 97% center; */
  border-radius: 4px;
}

.iconButton {
  width: 35px;
  height: 35px;
  background: #f8f8f8;
  border-radius: 4px;
  color: #172b4d;
  margin-left: 20px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.iconButton button {
  width: 20px;
  height: 20px;
  background-color: transparent !important;
  border: none;
  outline: none !important;
}

.filbtnnew {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgb(51, 90, 255);
  box-shadow: none;
  height: 35px;
  padding: 0px 15px;
  color: rgb(255, 255, 255) !important;
  font-family: Roboto, sans-serif !important;
}

.filbtnnew i {
  margin-right: 7px;
}

.filter-title {
  padding: 22px 20px;
  border-bottom: 1px solid #ebecf0;
  width: 100%;
  display: flex;
}
.filter-title h2 {
  font-weight: 500;
  font-size: 23px;
  color: #172b4d !important;
}
.filticon {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  cursor: pointer;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.accordion {
  width: 100%;
  max-width: 360px;
  margin: 30px auto 20px;
}

.accordion .link {
  cursor: pointer;
  display: block;
  padding: 15px 15px;
  color: #4d4d4d;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.accordion li:last-child .link {
  border-bottom: 0;
}

.accordion li i {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 15px;
  color: #595959;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.accordion li i.fa-chevron-down {
  right: 12px;
  left: auto;
  font-size: 13px;
}

.accordion li.open .link {
  color: #335aff;
}

.accordion li.open i {
  color: #335aff;
}

.accordion li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

/**
   * Submenu
   -----------------------------*/

.submenu {
  display: none;
  font-size: 14px;
}

.push {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.backBtn {
  font-size: 16px;
  text-align: right;
  position: absolute;
  right: 7px;
}
.backBtn:hover {
  color: #000;
}
.menufilter-right .backBtn {
  text-align: left;
}
.menufilter-top .backBtn,
.menufilter-bottom .backBtn {
  text-align: left;
}

.menufilter {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: fixed;
  top: 65px;
  z-index: 999;
  width: 280px;
  height: 100%;
}

.menufilter-left {
  left: -280px;
}
.menufilter-left.left-open {
  left: 0;
}
.menufilter-right {
  right: -280px;
}
.menufilter-right.right-open {
  right: 0;
}
.menufilter-top {
  width: 100%;
  top: -100%;
}
.menufilter-top.top-open {
  top: 0;
}
.menufilter-bottom {
  width: 100%;
  top: 100%;
}
.menufilter-bottom.bottom-open {
  top: 0;
}
.push {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.push-left {
  left: -280px;
}
.push-left.pushleft-open {
  left: 0;
}
.push-toleft {
  left: 280px;
}
.push-right {
  right: -280px;
}
.push-right .backBtn {
  text-align: left;
}
.push-right.pushright-open {
  right: 0;
}
/* .push-toright {
	left: -280px;
  } */
/*sahud end css*/

button::-moz-focus-inner {
  border: 0;
}
img:-moz-loading {
  visibility: hidden;
} /* hide alt text while loading */
/* Clearing */

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
/* Typography */

.mleft {
  margin-left: -1px !important;
}
em {
  font-style: italic;
  font-weight: 300;
}

a:focus,
a:hover {
  color: #23527c;
  text-decoration: none;
}
a {
  outline: none !important;
  color: #335aff;
}
/*Notification*/
.has-notify {
  cursor: pointer;
}
.open > .notification_main {
  display: block;
}
.notification_main {
  text-align: left;
  position: absolute;
  width: 320px;
  top: 33px;
  right: 0;
  left: auto;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  display: none;
  float: left;
  padding: 0;
  margin: 0;
  font-size: 13px;
  list-style: none;
  border-radius: 4px;
}
.notification_main .dropdown-footer {
  padding: 10px 15px;
  clear: both;
  border-top: 1px solid #e7eaf0;
}
.notification_main .dropdown-heading {
  padding: 8px 12px;
  border-bottom: 1px solid #e7eaf0;
  font-weight: 600;
  font-size: 14px;
}
.dropdown-notification > li.notification-item {
  padding: 10px;
  position: relative;
}
.dropdown-notification li.notification-item:hover {
  background: #f8f8f9;
  box-shadow: 0px 0px 2px #cccece;
}
.dropdown-notification > li.notification-item .media_icon {
  float: left;
  width: 40px;
  height: 40px;
  overflow: hidden;
  text-align: center;
  line-height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: #e9edf0;
  color: #597082;
}
.dropdown-notification > li.notification-item .media_icon i {
  font-size: 17px;
}
.dropdown-notification > li.notification-item .media_icon + .message {
  margin-left: 50px;
}
.pulse-button {
  width: 15px;
  background: #eb3822;
  height: 15px;
  position: absolute;
  border-radius: 50px;
  font-size: 10px;
  text-align: center;
  color: #fff;
  line-height: 15px;
  right: -6px;
  top: -4px;
  font-weight: 600;
}
.message h4 {
  margin: 0 0px 7px 0px;
  padding: 0;
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  color: #5b5b5b;
}
.message h5 {
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: #9d9d9d;
}
.notification_btn {
  border-radius: 2px;
  color: #fff !important;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  background: #0093ff !important;
  font-size: 13px;
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 8px 0px !important;
  font-weight: 600 !important;
}
.menu header .navbar-nav > li > a {
  padding: 0px;
}
.navbar-nav .nav-link {
  padding: 0px !important;
  margin-left: 20px !important;
}
.header-top {
  border-bottom: 1px solid #e6e6e6;
  margin: 0px;
}
.navbar-light {
  padding-right: 30px;
  padding-left: 30px;
  height: 60px;
  background: rgb(255, 255, 255);
  color: rgb(23, 43, 77);
  box-shadow: 0px 1px 4px 0 rgb(0 0 0 / 28%);
  width: 100%;
  display: block !important;
  padding-top: 0px;
  padding-bottom: 0px;
}
.profile_top_panel i {
  font-size: 18px;
  width: 28px;
  color: #9899a4;
}
.open > .profile_top_panel {
  display: block;
}
.profile_top_panel {
  text-align: left;
  position: absolute;
  display: none;
  padding: 0;
  width: 190px;
  top: 35px;
  right: 11px !important;
  left: auto !important;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
}
.profile_top_panel a {
  padding: 9px 15px !important;
  cursor: pointer;
  color: #4f4d4d;
  font-size: 13px;
  font-weight: 600;
}
.profile_top_panel a:hover {
  background-color: #fff;
  color: #00aeef;
}
.profile_top {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  margin-right: 5px;
}
.profile_top_panel .dropdown-item {
  color: #5b5b5b;
  outline: none;
  font-size: 13px;
  padding: 10px 13px;
  font-weight: 500;
  text-decoration: none;
}
.profile_top_panel .dropdown-item img {
  margin-right: 9px;
  width: 17px !important;
  float: left;
}
.user_name_top {
  padding: 10px 16px;
  border-bottom: 1px solid #e7eaf0;
  background: #f9f8f8;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  line-height: 18px;
  color: #000;
}
.top_nav-fixed {
  position: fixed;
  top: -3em;
  z-index: 999;
  width: 100%;
}
.emplo_backgro_head {
  /* background: #0093ff url(../img/back.jpg); */
  background-repeat: no-repeat;
  box-shadow: 0px 1px 4px #b5b5b5;
  padding: 0;
  height: 120px;
  background-size: cover;
}
.left_space_profile {
  padding: 15px;
  position: relative;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
.emp-profile {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  clear: both;
  position: relative;
}
.pro_img_new {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #335aff;
  color: #fff;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  margin: 13px 0px;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0;
  vertical-align: 0.255em;
  content: "";
  border-top: 0em solid;
  border-right: 0em solid transparent;
  border-bottom: 0;
  border-left: 0em solid transparent;
  margin-top: 0px;
  margin-right: 0px;
}
.collu_edit_panel {
  float: right;
  width: 40%;
  text-align: right;
}
.collu_edit_panel img {
  border: 1px solid #7f7f7f;
  width: 28px;
  display: block;
  text-align: center;
  height: 28px;
  line-height: 28px;
  border-radius: 4px;
  padding: 4px;
  float: right;
  opacity: 0.4;
}
.profile-img {
  text-align: left;
  margin-top: 0;
  position: relative;
  float: left;
  width: 51%;
  margin-bottom: 15px;
}
.profile-img img {
  width: 130px;
  height: 130px;
  background: #fff;
  object-fit: cover;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 0px 2px #bbbaba;
}
.profile_head2 {
  border-radius: 4px !important;
  border: 1px solid #e6e6e6 !important;
  margin-bottom: 15px !important;
}
.profile-head {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  margin: 0;
  border-radius: 0px;
  border-bottom: none;
  clear: both;
  height: 47px;
}
.profile-head22 {
  clear: both;
}
.profile-head22 h3 {
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  margin: 0px 0px 9px 0px;
}
.profile-head22 h4 {
  margin: 0 0px 25px 0px;
  font-size: 14px;
  color: #616161;
  font-weight: 500;
}
.profile-head .nav-tabs {
  margin-bottom: 0;
  border: none;
}
.profile-head .nav-tabs .nav-link {
  font-weight: 500;
  padding: 0;
  margin-right: 0;
  color: #616161;
  font-size: 14px;
  line-height: 47px;
  border: none;
  height: 47px;
  border-bottom: 1px solid transparent !important;
}
.profile-head .nav-tabs > li {
  float: left;
  line-height: 47px;
  height: 47px;
  margin: 0px 15px;
}
.profile-head .nav-tabs > li.active > a {
  background: transparent;
  border-bottom: 1px solid #0093ff !important;
  color: #0093ff !important;
  border: none;
}
.profile-head .nav-tabs > li a:focus {
  background: transparent;
  color: #0093ff;
  border-bottom: 1px solid #0093ff !important;
  border: none;
  outline: none;
}
.profile-head .nav-tabs > li a:hover {
  color: #0093ff;
  border-bottom: 1px solid #0093ff !important;
  border: none;
  background: transparent;
}
.profile_title_emal {
  clear: both;
  text-align: left;
  border-top: 1px solid #eee;
  padding-top: 15px;
}
.profile_title_emal ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.profile_title_emal ul li {
  display: block;
  position: relative;
  font-size: 13px;
  line-height: 35px;
  font-weight: 400;
}
.profile_title_emal ul li span {
  color: #616161;
}
.profile_title_emal ul li i {
  font-size: 17px;
  width: 20px;
  text-align: center;
  margin-right: 15px;
}
.main_tab_pro {
  clear: both;
  margin: 0px 60px 0px 60px;
  position: relative;
}
.main_tab_pro .tab-pane {
  padding: 0px;
}
.sidebar_tab_pro {
  clear: both;
  margin-left: 20px;
  height: 50px;
  border-radius: 4px;
}
.sidebar_tab_pro ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sidebar_tab_pro ul li {
  position: relative;
  display: block;
  float: left;
  height: 52px;
  line-height: 52px;
}
.sidebar_tab_pro ul li a {
  border-bottom: 1px solid transparent;
  padding: 0px;
  text-decoration: none;
  margin: 0px 15px;
  color: #616161;
  font-size: 14px;
  display: block;
  height: 50px;
  line-height: 50px;
  outline: none !important;
}
.sidebar_tab_pro ul li a:hover {
  color: #0093ff;
}
.sidebar_tab_pro ul li a.active {
  border-bottom: 1px solid #0093ff;
  color: #0093ff;
}
/**/
.annoucement_n {
  margin-bottom: 15px;
  background-color: #ffffff;
  border-radius: 4px;
  clear: both;
  border: 1px solid #e6e6e6;
}
.edit_section_profile {
  padding: 25px 20px;
  clear: both;
}
.edit_section_profile h1 {
  margin: 0px 0px 22px;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.all_view_list {
  padding: 0;
  min-height: 46px;
  list-style: none;
  margin-bottom: 15px;
}
.all_view_list li:first-child {
  font-weight: 600;
  line-height: 21px;
  font-size: 13px;
}
.all_view_list li:nth-child(2) {
  font-size: 13px;
  line-height: 19px;
  color: #616161;
}
.detail_section_profile:hover .edit_all_sec {
  opacity: 1;
}
.edit_all_sec {
  float: right;
  opacity: 0;
}
.edit_all_sec i {
  font-size: 12px;
}
.edit_all_sec a {
  text-decoration: none;
  color: #717382;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.4;
  top: -2px;
  position: relative;
}
.edit_all_sec img {
  width: 17px;
}
.top_detail_leave {
  clear: both;
  margin: 0px 0px 20px 0px;
}
.top_detail_leave h3 {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0px 5px 0;
  padding: 0;
  line-height: 21px;
  color: #616161;
}
.top_detail_leave ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.top_detail_leave ul li {
  display: block;
  width: 100%;
  margin: 0px 8px;
  float: left;
  text-align: center;
  background: #fff;
  padding: 10px;
  border-radius: 0px;
  border: 1px solid #e6e6e6;
}
.top_detail_leave ul li:first-child {
  margin-left: 0px;
}
.top_detail_leave ul li:last-child {
  margin-right: 0px;
}
.number_leave {
  padding: 0;
  font-size: 15px;
  font-weight: 600;
}
.number_leave a {
  text-decoration: none;
}
.number_leave span {
  font-size: 12px;
  font-weight: normal;
  padding-left: 4px;
}
.navbar-brand {
  padding: 12px 0;
}
.navbar-brand > img {
  display: block;
  height: 32px;
  width: auto;
  margin-top: 0px;
}
/*Dashboard*/
.detail_section_profile {
  margin-bottom: 25px;
  background-color: #ffffff;
  border-radius: 4px;
  clear: both;
  padding: 25px;
  border: 1px solid #e6e6e6;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
}
.title_section_profile {
  padding: 7px 0px;
}
.title_section_profile h2 {
  position: relative;
  margin: 0px;
  /* font-family: Poppins, sans-serif; */
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0.00714em;
  color: rgb(23, 43, 77);
  font-weight: 600;
}
.view_section_profile {
  padding: 0px;
  clear: both;
}
/* draggable targets */
[data-draggable="target"] h2 {
  margin: 0px 0px 15px 0px;
  padding: 0;
  clear: both;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}
[data-draggable="target"] {
  float: left;
  list-style-type: none;
  width: 100%;
  margin: 0 0.5em 0.5em 0;
  background: #fff;
  padding: 10px;
  box-shadow: 0px 1px 5px #eee;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
}

/* drop target state */
[data-draggable="target"][aria-dropeffect="move"] {
  border-color: #68b;
  background: #fff;
}
/* drop target focus and dragover state */
[data-draggable="target"][aria-dropeffect="move"]:focus,
[data-draggable="target"][aria-dropeffect="move"].dragover {
  outline: none; /*	box-shadow:0 0 0 1px #fff, 0 0 0 3px #68b;
*/
}

/* draggable items */
[data-draggable="item"] {
  display: block;
  list-style-type: none;
  cursor: all-scroll;
  margin: 0 0 12px 0;
  padding: 5px;
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px #a8dcee;
  border-radius: 2px;
  line-height: 15px;
  background: rgba(244, 251, 253, 0.54);
  color: #626262;
}
/* items focus state */
[data-draggable="item"]:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px #a8dcee;
}
[data-draggable="item"]:hover {
  background: #0ba9f4;
  color: #fff;
}
[data-draggable="item"]:hover span {
  opacity: 0.9;
  color: #fff;
}
/* items grabbed state */
[data-draggable="item"][aria-grabbed="true"] {
  background: #0ba9f4;
  color: #fff;
}
[data-draggable="target"] img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border-radius: 50px;
}
[data-draggable="item"] span {
  margin: 0;
  padding-left: 10px;
  font-weight: 600;
  font-size: 12px;
  opacity: 0;
  color: #000;
}
.btn_text_add {
  width: 100%;
  color: #80c342;
}
.btn_text_remove {
  width: 100%;
  color: #e11d04;
}
.btn_text_nochange {
  width: 100%;
}
.recommen {
  font-size: 15px;
  margin: 0 0px 5px 0px;
  padding: 0;
  font-weight: 600;
}
.abutn {
  text-decoration: underline;
  font-size: 13px;
}
.width_90 {
  width: 90px !important;
}
.recommen i {
  color: #f59b90;
  font-size: 17px;
}
/*form*/
.form_pms {
  position: relative;
  margin: 0px 0px 15px 0px;
}
.form_pms input {
  -webkit-appearance: none;
  width: 100%;

  padding: 0 7px;
  height: 28px;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #d2d9df;
  background: #fff;
  border-radius: 0;
  color: #717382;
  transition: all 0.15s ease;
}
.date_pickhead {
  /* background: transparent url(../img/date.svg) !important; */
  background-repeat: no-repeat !important;
  background-position: 98% !important;
  background-size: 15px !important;
}
textarea {
  height: 5rem;
  border: 1px solid #d2d9df;
  width: 100%;
  font-size: 14px;
  padding: 4px 10px;
  color: #717382;
}
.label_form {
  font-size: 13px;
  font-weight: 500;
  color: #4e5163;
  margin-bottom: 4px;
}
.btn_transparencolor {
  background: transparent;
  border: 1px solid #01aef0;
  border-radius: 2px;
  margin-right: 10px;
  font-size: 13px;
  color: #01aef0;
  font-weight: 600;
  padding: 5px 20px;
  text-transform: capitalize;
}
.btn_bluecolor {
  border-radius: 2px;
  border: 1px solid #01aef0;
  transition: 0.2s ease-out;
  color: #fff;
  margin: 0;
  word-wrap: break-word;
  text-transform: capitalize;
  background: #01aef0;
  position: relative;
  cursor: pointer;
  font-size: 13px;
  text-decoration: none;
  z-index: 1;
  padding: 5px 20px;
  font-weight: 600;
}
.form_pms .select_box {
  -webkit-appearance: none;
  width: 100%;

  padding: 0 7px;
  height: 28px;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #d2d9df;
  /* background: transparent url(../img/custom.png); */
  border-radius: 0;
  color: #616161;
  transition: all 0.15s ease;
  background-repeat: no-repeat;
  background-position: 98%;
}
/*toggle*/
.onoffswitch {
  position: relative;
  width: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #d2d9df;
  border-radius: 1px;
  margin: 0;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 22px;
  padding: 0;
  line-height: 22px;
  font-size: 11px;
  color: white;
  font-weight: 600;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "Yes";
  padding-left: 12px;
  background-color: #80c342;
  color: #ffffff;
}
.onoffswitch-inner:after {
  content: "No";
  padding-right: 15px;
  background-color: #e6e6e6;
  text-align: right;
  color: #000;
}
.onoffswitch-switch {
  display: block;
  width: 14px;
  margin: 5px;
  background: #ffffff;
  border-radius: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 37px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
/*toggle 2*/
.onoffswitch22 {
  position: relative;
  width: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin: 0 !important;
}
.onoffswitch22-checkbox {
  display: none;
}
.onoffswitch22-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  margin: 0;
}
.onoffswitch22-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch22-inner:before,
.onoffswitch22-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 24px;
  padding: 0;
  line-height: 24px;
  font-size: 11px;
  color: white;
  font-weight: 600;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.onoffswitch22-inner:before {
  content: "Yes";
  padding-left: 12px;
  background-color: #80c342;
  color: #ffffff;
  text-align: left;
}
.onoffswitch22-inner:after {
  content: "No";
  padding-right: 15px;
  background-color: #e6e6e6;
  text-align: right;
  color: #000;
}
.onoffswitch22-switch {
  display: block;
  width: 16px;
  margin: 4px;
  background: #ffffff;
  border-radius: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 37px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch22-checkbox:checked + .onoffswitch22-label .onoffswitch22-inner {
  margin-left: 0;
}
.onoffswitch22-checkbox:checked + .onoffswitch22-label .onoffswitch22-switch {
  right: 0px;
}

.status_bg {
  background: #fcfdfd;
  padding: 18px 10px;
  border: 1px solid #ececec;
}
h3 {
  margin: 0px 0px 7px 0px;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
}

.add_btn {
  height: 28px;
  font-size: 15px;
  line-height: 28px;
  border: none;
  width: 28px;
  background: #335aff;
  color: #fff;
  border-radius: 1px;
}
.remove_btn {
  height: 28px;
  font-size: 15px;
  width: 28px;
  line-height: 28px;
  border: none;
  border-radius: 1px;
  background: #ee3823;
  color: #fff;
}

.add_btnnew {
  height: 32px;
  font-size: 15px;
  line-height: 32px;
  border: 1px solid #335aff;
  width: 32px;
  background: #fff;
  color: #335aff;
  border-radius: 50px;
  outline: none !important;
}
.remove_btnnew {
  height: 32px;
  outline: none !important;
  font-size: 15px;
  width: 32px;
  line-height: 32px;
  border: 1px solid #ee3823;
  border-radius: 50px;
  background: #fff;
  color: #ee3823;
}
.status_bg22 {
  margin-top: 22px;
}
.feedback_table {
  border: 1px solid #ececec;
}
.feedback_table td {
  border: 1px solid #ececec;
  padding: 7px;
}
.feedback_table td:nth-child(2) {
  background: rgba(244, 251, 253, 0.54);
  width: 50%;
}
/*rating*/
.rating {
  border: none;
  float: left;
}
.rating > input {
  display: none;
}
.rating > label:before {
  margin: 0px 6px 0px 0px;
  font-size: 20px;
  font-family: "FontAwesome", sans-serif;
  display: inline-block;
  content: "\f005";
}
.rating > .half:before {
  content: "\f089";
  position: absolute;
}
.rating > label {
  color: #ddd;
  float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */ .rating:not(:checked) > label:hover, /* hover current star */ .rating:not(:checked) > label:hover ~ label {
  color: #ffd700;
} /* hover previous stars in list */
.rating > input:checked + label:hover, /* hover current star when changing rating */ .rating > input:checked ~ label:hover, .rating > label:hover ~ input:checked ~ label, /* lighten current selection */ .rating > input:checked ~ label:hover ~ label {
  color: #ffed85;
}
.star-rating i {
  color: #01aef0;
  font-size: 26px;
}
.progress22 {
  font-size: 13px;
  height: 20px;
  background: #f2f2f2;
  border-radius: 2px;
}
.progress-bar2 {
  background-color: #01aef0;
  color: #fff;
  text-align: center;
}
.table_all {
  width: 100%;
}
.table_all th {
  border: none;
  font-weight: 500;
  position: sticky;
  top: 0px;
  z-index: 1;
  font-size: 13px;
  text-transform: capitalize;
  background: #fafafa;
  white-space: nowrap;
  padding: 9px;
}
.table_all td {
  border-top: 1px solid #ececec;
  padding: 18px 7px;
  font-size: 14px;
  color: #172b4d;
}
.apply_filter {
  border: 1px solid #ececec;
  margin-bottom: 15px;
  clear: both;
}
.apply_filter h2 {
  font-size: 13px;
  margin: 0;
  background: #f9f9f9;
  position: relative;
  padding: 10px;
  font-weight: 600;
}
.raise_btn {
  position: absolute;
  right: 13px;
  font-size: 19px;
  color: #535353;
  top: 0px;
}
.anc_btn {
  color: #9d9d9d;
  display: block;
  padding: 7px 0px;
}
/*progress*/
.progress {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}
.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid #dbdbdb;
  position: absolute;
  top: 0;
  left: 0;
}
.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress .progress-left {
  left: 0;
}
.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 3px;
  border-style: solid;
  position: absolute;
  top: 0;
}
.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress .progress-right {
  right: 0;
}
.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}
.progress .progress-value {
  width: 100%;
  border-radius: 50%;
  font-size: 11px;
  line-height: 40px;
  text-align: center;
}
.progress.blue .progress-bar {
  border-color: #80c342;
}
.progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}
.progress.yellow .progress-bar {
  border-color: #80c342;
}
.progress.yellow .progress-left .progress-bar {
  animation: loading-3 1s linear forwards 1.8s;
}
@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36deg);
    transform: rotate(36deg);
  }
}
@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(126deg);
    transform: rotate(126deg);
  }
}
.name_box {
  display: flex;
  clear: both;
  line-height: 30px;
  font-size: 13px;
  font-weight: 600;
}
.name_box img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.collapse.in {
  display: block;
}
#collapseExample {
  clear: both;
  padding: 0px;
}
.rate {
  text-align: center;
  margin-bottom: 7px;
}
.emoji {
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 12px;
}
.slider_rate {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 4px;
  background: #e1e6f9;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.slider_rate::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  top: -6px;
  left: -12px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 3px #ddd;
  position: relative;
  cursor: grab;
}
.slider_rate::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  top: -6px;
  left: -12px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 3px #ddd;
  position: relative;
  cursor: grab;
}
.span_pseudo,
.chiller_cb span:before,
.chiller_cb span:after {
  content: "";
  display: inline-block;
  background: #fff;
  width: 0;
  height: 0.2rem;
  position: absolute;
  transform-origin: 0% 0%;
}
.chiller_cb {
  position: relative;
  display: flex;
  height: 30px;
  top: -10px;
}
.chiller_cb input {
  display: none;
}
.chiller_cb input:checked ~ span {
  background: #335aff !important;
  border-color: #335aff;
}
.chiller_cb input:checked ~ span:before {
  width: 11px;
  height: 2px;
  transition: width 0.1s;
  transition-delay: 0.3s;
}
.chiller_cb input:checked ~ span:after {
  width: 6px;
  height: 2px;
  transition: width 0.1s;
  transition-delay: 0.2s;
}
.chiller_cb input:disabled ~ span {
  background: #ececec;
  border-color: #dcdcdc;
}
.chiller_cb input:disabled ~ label {
  color: #dcdcdc;
}
.chiller_cb input:disabled ~ label:hover {
  cursor: default;
}
.chiller_cb label {
  padding-left: 29px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin-bottom: 0;
}
.chiller_cb span {
  display: inline-block;
  width: 17px;
  height: 17px;
  border: 1px solid #ccccd8;
  position: absolute;
  left: 0;
  border-radius: 2px;
  transition: all 0.2s;
  z-index: 1;
  box-sizing: content-box;
}
.chiller_cb span:before {
  transform: rotate(-55deg);
  top: 13px;
  left: 7px;
}
.chiller_cb span:after {
  transform: rotate(35deg);
  bottom: 6px;
  left: 4px;
}
.switch6 {
  margin: 0 auto;
  border: 1px solid #eee;
  background: #fbfbfb;
  border-radius: 0px;
}
.switch6-light > span,
.switch-toggle > span {
  color: #000000;
}
.switch6-light span span,
.switch6-light label,
.switch-toggle span span,
.switch-toggle label {
  color: #2b2b2b;
}
.switch-toggle a,
.switch6-light span span {
  display: none;
}
.switch6-light {
  display: block;
  height: 27px;
  position: relative;
  overflow: visible;
  padding: 0px;
  margin: 0px;
}
.switch6-light * {
  box-sizing: border-box;
}
.switch6-light a {
  display: block;
  transition: all 0.3s ease-out 0s;
}
.switch6-light label,
.switch6-light > span {
  line-height: 27px;
  vertical-align: middle;
}
.switch6-light label {
  font-weight: 500;
  margin-bottom: px;
  max-width: 100%;
}
.switch6-light input:focus ~ a,
.switch6-light input:focus + label {
  outline: 1px dotted rgb(136, 136, 136);
}
.switch6-light input {
  position: absolute;
  opacity: 0;
  z-index: 5;
}
.switch6-light input:checked ~ a {
  right: 0%;
}
.switch6-light > span {
  position: absolute;
  left: -100px;
  width: 100%;
  margin: 0px;
  padding-right: 100px;
  text-align: left;
}
.switch6-light > span span {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
  display: block;
  width: 50%;
  margin-left: 100px;
  text-align: center;
}
.switch6-light > span span:last-child {
  left: 50%;
}
.switch6-light a {
  position: absolute;
  right: 50%;
  top: 0px;
  z-index: 4;
  border-radius: 0px;
  display: block;
  width: 50%;
  height: 100%;
  padding: 0px;
  background: #80c342;
  border: none;
}
.buton_case {
  background: #f4f4f4;
  border: 1px solid #d7d7d7;
  padding: 4px 10px;
  outline: none !important;
  width: 215px;
  text-align: left;
  border-radius: 4px;
}
.input-group-text {
  padding: 3px 10px;
}
.input-group-prepend {
  margin-left: -1px;
  margin-right: auto;
}
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: nowrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.form-control {
  height: 28px;
  font-size: 13px;
  box-shadow: none !important;
  outline: none;
  border-radius: 0px;
}
.width_500 {
  width: 500px;
}
.width_600 {
  width: 600px;
}
.width_700 {
  width: 700px;
}
.width_800 {
  width: 800px;
}
.width_900 {
  width: 900px;
}
.width_1000 {
  width: 1000px;
}
.table_new td {
  padding: 5px;
}
.table_new th {
  padding: 5px;
  font-weight: 600;
}
.table_new th:first-child {
  padding-left: 0;
}
.table_new td:first-child {
  padding-left: 0;
}
.table_new td:last-child {
  padding-right: 0;
}
.employee_gols_new h6 {
  font-weight: 600;
  margin: 0;
  padding: 7px 0px;
  font-size: 13px;
}
.new_box_tab {
  clear: both;
  background: #fff;
}
.new_box_tab th {
  background: rgb(238, 56, 35);
  padding: 5px;
  text-align: center;
  font-weight: 600;
  width: 50%;
  color: #fff;
  border: 1px solid #e3e3e3;
}
.new_box_tab th:last-child {
  background: rgb(128, 195, 66);
}

.per_col {
  font-size: 14px;
}
.pot_col {
  font-size: 14px;
}

.per_col i {
  color: rgb(238, 56, 35);
  font-size: 16px;
}
.pot_col i {
  color: rgb(128, 195, 66);
  font-size: 16px;
}
.sc_mar {
  margin-top: 153px;
}
.new_box_tab td {
  padding: 7px;
  border: 1px solid #e3e3e3;
}
.heading_top {
  text-align: center;
  font-size: 13px;
  background: #fbfbfb;
  font-weight: 600;
}
.box_empl {
  display: block;
  margin: 0;
  color: #626262;
}
.box_empl img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50px;
}

.wig_new {
  background: #f3f8f9;
}

.fitting_box {
  clear: both;
}
.fitting_box .panel-title {
  font-size: 14px;
  background: #f9f9f9;
  position: relative;
  border: 1px solid #eee;
  margin-bottom: 0;
}

.fitting_box .panel-title a {
  display: block;
  padding: 8px;
  color: #000;
}
.fitting_box .panel-body {
  padding: 10px;
  border: 1px solid #eee;
  background: #fdfdfd;
  border-top: none;
}

.fitting_box .panel {
  margin-bottom: 15px;
}

.ico_right {
  font-size: 26px;
  position: absolute;
  right: 11px;
  top: 0px;
  color: #959595;
}

.modal-header {
  padding: 15px;
  border-bottom: 0px solid #e6e6e6;
}
.modal-title {
  margin: 0;
  line-height: 20px;
  font-size: 14px;
  padding: 0;
  font-weight: 600;
}
.modal-header .close {
  padding: 0;
  margin: 0;
  height: 13px;
  width: 13px;
  line-height: 13px;
  opacity: 0.6;
  outline: none;
}
.ess_modal {
  max-width: 400px;
}

.tooltip .tooltip-inner {
  max-width: 200px;
  padding: 1px 3px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.remove-file {
  display: block;
  margin: 50px;
}

.nav-fostrap {
  display: flex;
  justify-content: center;
  z-index: 120;
  /* font-family: Poppins, sans-serif; */
}

.nav-fostrap ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
}

.nav-fostrap li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #def1f0;
}

.nav-fostrap li a {
  padding: 19px 18px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  display: inline-block;
  outline: 0;
  font-weight: 500;
  text-transform: capitalize;
  border-bottom: 2px solid transparent;
}

.nav-fostrap li a i {
  font-size: 10px;
  margin-left: 2px;
  position: relative;
  top: -1px;
}

.nav-fostrap li:hover ul.dropdown {
  display: block;
}

.nav-fostrap li ul.dropdown {
  position: absolute;
  display: none;
  width: 210px;
  padding-top: 0;
  z-index: 110;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 8px 9px #f2f2f2;
  border-top: none;
}

.nav-fostrap li ul.dropdown li {
  display: block;
  list-style-type: none;
}

.nav-fostrap li ul.dropdown li a {
  padding: 6px 9px;
  font-size: 13px;
  color: #616161;
  display: block;
  font-weight: 400;
}
.nav-fostrap li:hover a {
  border-bottom: 2px solid #1266f1;
  color: #1266f1;
}
.nav-fostrap li a.active {
  border-bottom: 2px solid #1266f1;
  color: #1266f1;
}

.nav-fostrap li ul.dropdown li a:hover {
  background: #dde6ea;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #def1f0;
  position: relative;
  top: 15px;
  right: -5px;
  content: "";
}
.title-on-mobile {
  display: none;
}
@media only screen and (max-width: 900px) {
  .heag_new {
    margin: 15px auto;
  }

  .nav-fostrap {
    background: #e5f5fb;
    width: 200px;
    display: block;
    position: fixed;
    left: -200px;
    top: 50px;
    bottom: 0px;
    -webkit-transition: left 0.25s ease;
    -moz-transition: left 0.25s ease;
    -ms-transition: left 0.25s ease;
    -o-transition: left 0.25s ease;
    transition: left 0.25s ease;
    margin: 0;
    border: 0;
    border-radius: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 105;
    scrollbar-width: thin;
  }
  .title-on-mobile {
    position: fixed;
    display: block;
    top: 10px;
    font-size: 20px;
    left: 100px;
    right: 100px;
    text-align: center;
    color: #fff !important;
  }
  .nav-fostrap.visible {
    left: 0px;
    -webkit-transition: left 0.25s ease;
    -moz-transition: left 0.25s ease;
    -ms-transition: left 0.25s ease;
    -o-transition: left 0.25s ease;
    transition: left 0.25s ease;
    z-index: 90;
  }

  .nav-bg-fostrap {
    display: inline-block;
    vertical-align: middle;
    height: 50px;
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
  }

  .navbar-fostrap {
    display: inline-block;
    vertical-align: middle;
    height: 50px;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px;
    z-index: 102;
  }

  .navbar-fostrap span {
    height: 2px;
    background: #3498db;
    margin: 5px;
    display: block;
    width: 20px;
  }

  .nav-fostrap ul {
    padding-top: 0px;
  }

  .nav-fostrap li {
    display: block;
  }

  .nav-fostrap li a {
    display: block;
    color: #505050;
    font-weight: 600;
    padding: 5px 10px;
    font-size: 13px;
  }

  .nav-fostrap li ul.dropdown {
    position: relative;
    display: block;
    width: 100%;
  }

  .nav-fostrap li ul.dropdown li a {
    padding: 5px 10px;
  }

  .cover-bg {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .main_tab_pro {
    margin: 10px;
  }
  .width_600 {
    width: 100%;
  }
  .width_700 {
    width: 100%;
  }
  .width_800 {
    width: 100%;
  }
  .navbar-nav li:first-child {
    display: none;
  }
  .navbar-light {
    padding: 0px 10px;
  }
  .navbar-brand {
    margin-left: 40px;
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 120;
}

/* form starting stylings ------------------------------- */
.zimyogroup {
  position: relative;
  margin-bottom: 30px;
  font-family: Roboto, sans-serif;
}
.zimyoinput {
  font-size: 14px;
  padding: 8px 15px;
  display: block;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #c5c7cc;
  height: 50px;
}
.zimyoinput:focus {
  outline: none;
}
/* LABEL ======================================= */
.zimyolabel {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 15px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state > .zimyoinput:focus ~ .zimyolabel, .zimyoinput:valid ~ .zimyolabel*/
.zimyoinput:focus ~ .zimyolabel,
.zimyoinput:valid ~ .zimyolabel {
  top: -7px;
  font-size: 12px;
  color: #757575;
  background: #fff;
  padding: 0px 1px;
}

.zimyoinput:focus ~ .zimyolabel,
.zimyoinput ~ .zimyolabel {
  top: -7px;
  font-size: 12px;
  color: #757575;
  background: #fff;
  padding: 0px 1px;
}

/* active state */
.zimyoinput:focus ~ .bar:before,
.zimyoinput:focus ~ .bar:after {
  width: 50%;
}
.zimyoinput:-webkit-autofill,
.zimyoinput:-webkit-autofill:hover,
.zimyoinput:-webkit-autofill:focus,
.zimyoinput:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

/* .zimyoinput {
    font-size: 14px;
    padding: 8px 15px;
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #c5c7cc;
    height: 50px;
} */

.namebody {
  padding: 30px;
  clear: both;
}

.flex-between-center,
.flex-between-end {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-between-center {
  -webkit-align-items: center;
  align-items: center;
}
.flex-between-start,
.start-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.start-flex {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.end-flex,
.flex-end-center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.flex-end-center {
  -webkit-align-items: center;
  align-items: center;
}

.modaahead {
  color: rgba(23, 43, 77, 1);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
}

.searchmain {
  width: 100%;
  height: 35px;
  /* background: url(../img/search.svg); */
  border: 2px solid #ebecf0;
  border-radius: 4px;
  padding: 12px 12px 12px 31px;
  cursor: pointer;
  background-position: 9px;
  background-repeat: no-repeat;
  background-size: 13px;
}

.dropdown-menu li {
  display: block !important;
  padding: 0 !important;
}
.css-13he3us {
  display: contents !important;
  -webkit-justify-content: space-between;
  justify-content: inherit !important;
}

.collumbox22 {
  border-right: 1px solid #f0f0f0;
  display: flex;
}

.candidates {
  display: flex;
  width: 100%;
}
.candate_img {
  display: flex;
  align-items: flex-start;
}
.nameletter {
  width: 32px;
  height: 32px;
  background: #335aff;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  line-height: 32px;
  text-transform: capitalize;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.candidateinfo {
  padding-left: 10px;
  width: 100%;
}
.candidateinfo h2 {
  text-transform: capitalize;
  margin: 0px;
  font-weight: 400;
  padding: 0px;
  font-size: 14px;
  line-height: 34px;
  color: #172b4d;
}
.candidateinfo h2 a {
  color: #172b4d;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0px;
}

.compute_btn {
  background: rgba(0, 205, 55, 0.1);
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  width: 98px;
  height: 30px;
  border: none;
  outline: none !important;
  color: #00cd37;
}

.iconMenuu {
  user-select: none;
  cursor: pointer;
  width: 22px;
  height: 22px;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.computebtn {
  height: 30px;
  background: rgba(0, 205, 55, 0.1);
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  text-align: center;
  padding: 0px 15px;
  line-height: 30px;
  font-weight: 500;
  font-size: 14px;
  color: #00cd37 !important;
  white-space: nowrap;
  display: block;
}

.wrating {
  width: 120px !important;
}
.wcomment {
  width: 200px !important;
}
.wupload {
  width: 200px !important;
}
.range-slider {
  position: relative;
  margin-bottom: 30px;
}

.range-slider__input {
  width: 100%;
  height: 46px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.range-slider__input:focus {
  outline: none;
}

/**
   * 1. Remove outline on FF.
   */
.range-slider__input::-moz-focus-outer {
  border: 0;
  /* [1] */
}

.range-slider__input::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background-color: #edeef2;
}

.range-slider__input::-webkit-slider-thumb {
  width: 22px;
  height: 22px;
  margin-top: -10px;
  border-radius: 50%;
  background: #335aff;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  z-index: 1 !important;
}

.range-slider__input::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
}

.range-slider__input::-moz-range-thumb {
  width: 22px;
  height: 22px;
  margin-top: -10px;
  border-radius: 50%;
  background: #335aff;
  cursor: pointer;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  z-index: 1 !important;
}

.range-slider__input::-ms-track {
  width: 100%;
  height: 4px;
  border-color: transparent;
  border-width: 2px 0;
  background: transparent;
  color: transparent;
  cursor: pointer;
  box-sizing: border-box;
}

.range-slider__input::-ms-fill-lower {
  background: #666;
  border-radius: 0;
  height: 4px;
  box-sizing: border-box;
}

.range-slider__input::-ms-fill-upper {
  background: #edeef2;
  border-radius: 0;
  height: 4px;
  box-sizing: border-box;
}

.range-slider__input::-ms-thumb {
  width: 22px;
  height: 22px;
  margin-top: -10px;
  border-radius: 50%;
  background: #335aff;
  cursor: pointer;
  appearance: none;
  box-sizing: border-box;
  z-index: 1 !important;
}

/**
   * The container for the dot elements to sit in.
   */
.range-slider__dots {
  display: none;
  justify-content: space-between;
  position: relative;
  left: 0;
}
.range-slider__dots span {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #edeef2;
  position: relative;
  top: -57px;
  border-radius: 50%;
}

.slidertextnew {
  display: flex;
  justify-content: space-between;
  position: relative;
  font-size: 14px;
}
.slidertextnew span {
  float: left;
  text-align: right;
  width: 20%;
}

.addmoreheading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 7px;
}

.allocatetable {
  max-height: 300px;
  min-height: 300px;
  overflow-y: auto;
  overflow-x: auto;
  padding: 0px;
}
.allocatetable22 {
  max-height: 370px;
  /* overflow-y: auto;
    overflow-x: auto; */
  padding: 0px;
  margin-bottom: 15px;
}
.error {
  display: flex;
  justify-content: right;
  color: red;
  font-size: 12px;
}


/*Review Confi*/

.setting-scroll{
    display: flex;
    height: calc(100vh - 120px);
    overflow: hidden;
    width:100%;}

.navigationLeft{
  display: flex;
  flex-direction: column;
    width: 300px;
    border-right: 1px solid #ebecf0;
}

.nav-pills{
    display: flex;
    flex-direction: column;
    margin: 20px 10px 0 30px !important;
    overflow-y: auto;
    padding-right: 10px;
    height: calc(100vh - 220px);
    overflow-x: hidden;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  .config-half-Table{
    width: 50% !important;
    border:1px solid #f0f0f0
  }
  .config-half-Table td {
    padding:20px;
    font-size: 18px;
    font-weight: 500;
   border:1px solid #f1f1f1;
   font-family: Roboto, sans-serif;
  }
  table.dataTable thead th,
  table.dataTable thead td {
    font-size: 14px;
    text-transform: capitalize;
    padding: 10px 7px;
    border-bottom: 1px solid #f0f0f0;
    white-space: nowrap;
    background: #fff;
  }
  .config-half-Table td {
   width:50%;
   height: 90px;
  }
  
  table.dataTable thead th {
    background: #f2f2f2 !important;
    font-weight: bold ;
    position: sticky;
    top: -20px !important;
    z-index: 1;
    text-align: left;
  }
  
  table.dataTable tbody tr {
    background-color: #ffffff;
  }
  
  table.dataTable tbody th,
  table.dataTable tbody td {
    padding: 12px 7px;
    font-size: 14px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  
.nav-link {
    text-align: left;
    text-transform: capitalize;
    margin: 0px;
    font-family: system-ui;
    font-size: 15px;
   
    letter-spacing: 0em;
    font-weight: 500;
    padding: 0.5rem 10px;
    background: transparent;
    color: rgba(0, 0, 0, 0.85);
    justify-content: flex-start;
    -webkit-box-align: center;
    height: 50px;
    line-height: 34px;
    white-space: nowrap;
    align-items: center;
    text-decoration: none;
  }
  
   .tab-active {
    color: rgb(51, 90, 255) !important;
    background: rgba(51, 90, 255, 0.05);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .nav-link:hover {
    color: rgb(51, 90, 255);
    background: rgba(51, 90, 255, 0.05);
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  /*********************************************Review Appraisal Cycle********************************/
  .active-calendar{
    max-width: 100%;
    overflow-x: auto;
  }

  .active-calendar ol {
    display: flex;
    height: 1px;
    margin: 35px 15px 60px 15px;
    background: #ddd;
    list-style: none;
  }

  .active-calendar li {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 50px;
    text-align: center;
  }

  .KPI-Input{
    position: relative;
  }
  .KPI-Input Input {
    width:100%;
    height:50px;
    border-radius: 2px;
  }
  .animated-label{
    position: absolute;
    top:-10px;
    font-size: 12px;
    font-weight: normal;
    color: #757575;
    background: #fff;
    padding: 0px 1px;
    left:11px;
   
  }

  .pageHeading{
    font-family: 'Poppins' , sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    padding: 7px;
    line-height: 24px;
    color: #172B4D;
  }

  .rtable {
    vertical-align: top;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border:none !important;
  }
  
  .rtable th {
    font-size: 13px;
    text-transform: capitalize;
    background: #fafafa;
    font-weight: 500;
    padding: 9px;
    text-align: left;
   border: none !important;
    font-family: 'Roboto' , sans-serif;
  }
  .rtable td {
    padding: 7px;
    /* border: 1px solid #f0f0f0; */
    border:none !important;
    font-size: 13px;
    /* height:70px; */
    border-bottom: 2px solid #f0f0f0 !important;
  }

  /*******************************************WORKFLOW*************************************************/

  .dropdown-body{
    position:relative;
    margin-top:6px;
  }
  .tab-list{
    display: flex;
    background-color: white;
    justify-content: center;

  }

  .config-container{
    display: flex;
  }

  .right-page-section {
    width:100%;
     margin-right:10px;
     margin-top:10px;
  }

  .column-container{
    display:flex;
    justify-content: space-between;
  }

  .workflow-column{
    display: flex;
    flex-direction: column;
    width: 48%;
    gap:30px
  }
  /** media queries **/

  
.review-left-menu{
  display: flex;
  background-color: white;
  justify-content: center;
}
.tab-active {
  text-align: left;
  text-transform: capitalize;
  margin: 0px;
  font-family: system-ui;
  font-size: 15px;
  letter-spacing: 0em;
  font-weight: 500;
  padding: 0.5rem 10px;
  color: rgb(51, 90, 255);
  background: rgba(51, 90, 255, 0.05);
  -webkit-box-shadow: none;
  box-shadow: none;
  justify-content: flex-start;
  -webkit-box-align: center;
  height: 50px;
  line-height: 34px;
  white-space: nowrap;
  align-items: center;
  text-decoration: none;
  
}

.nav-link:hover {
  color: rgb(51, 90, 255);
  background: rgba(51, 90, 255, 0.05);
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* Description.css */

.custom-descriptions {
    /* Default styles for the Description component */
 
  }
  
  .custom-descriptions-bordered {
    /* Default styles for the bordered Description component */
  }
  
  .custom-descriptions-title {
    /* Default styles for the title of Description component */
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;   
  }
  
  .custom-descriptions-view {
    /* Default styles for the view container of Description component */
  }
  
  .custom-descriptions-item {
    /* Default styles for each item within Description component */
  }
  
  .custom-descriptions-item-label {
    /* Default styles for the label of each item within Description component */
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    letter-spacing: 0.02em;
    color: #4F4F4F;
  }
  
  .custom-descriptions-item-content {
    /* Default styles for the content of each item within Description component */
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    // text-align: center;
    color: #172B4D;
  }
  
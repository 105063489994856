.filter__box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 0px 8px 10px;
  gap: 10px;
  cursor: pointer;
  height: 36px;
  background: #ffffff;
  border: 1.5px solid #f5f5f5;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;

  &.checked {
    background-color:#335AFF1A;
  }
  &:hover{
    border-color: #aba7a7;
  }
}





.filters__default__text {
  height: 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.06em;
  color: #30323e;
  flex: none;
  order: 0;
  flex-grow: 0;

  &.checked__text{
    color: #335AFF;
  }
}


.paper__container{
  padding: 10px;
  width: 230px;
  border: 1px solid #E8E8EA;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
  border-radius: 4px;
}

.search__component {
  height: 40px;
  background: #ffffff;
  border: 1px solid #ebecf0;
  border-radius: 3px;
  padding: 2px 12px;
  cursor: pointer;
}

/* Modal Css */
.membercard__heading {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    margin-bottom: 10px;
}

.membercardheading__icon {
    color: #0A7AFF;
    margin-right: 20px;
}

.schedule__employee__heading {
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #757575;
}

.schedule__employee_search {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background: #FFFFFF;
    /* border: 1px solid #E8E8EA; */
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    color: #172B4D;
    margin-left: 8px;
}

.schedule__employee {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background: #FFFFFF;
    border: 1px solid #E8E8EA;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    color: #172B4D;
    margin-left: 8px;
}

.modalheading__inputs {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    color: #101010;
}

.moreDot {
    position: absolute;
    z-index: 9;
    top: 21px;
    right: 7px;
}

.memberLinkcard {
    text-align: center;
    flex-direction: column;
    align-items: center;
    display: flex;
}

.add_skill {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #335AFF;
    margin-left: 15px;
    white-space: nowrap;
}

.item__centerdirector {
    display: flex;
    align-items: center;
}

.skill__btns {
    display: inline-block;
    padding: 2px 6px;
    width: auto;
    height: 24px;
    background: #E6ECFF;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    word-break: break-all;
    color: #4C70FF;
}

.closeRatingicon {
    color: #757575;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer;
}

.appreciation__card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 10px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;

}

.appreciation__card__header {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    margin-top: 0px;
    margin-bottom: 15px;
    text-transform: capitalize;
    color: #000000;
}

.appreciation__card h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin: 0px 0px 4px 0px;
    padding: 0;
    color: #172B4D;
}

.appreciation__card p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    color: #A5A3A9
}

.appreciation__card:hover {
    /* border: 1px solid rgba(10, 122, 255, 0.2);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02); */
    cursor: pointer;
}

.form-error {
    font-size: 13px;
    color: #ff0000;
    padding: 0px;
    margin: 9px 0px 0px 0px;
    float: left;
    text-transform: capitalize;
}


/* upload Images/Pdf */
.footer__upload_assets {
    opacity: 0.5 !important;
    /* border-right: 1px solid #a3a2a2; */
    padding-right: 15px;
}

.main__upload__box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.all__upload__images {
    margin: 1rem 0.5rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    width: 70px;
    height: 70px;
    border: 1px solid #333;
}

.all__upload__images>img {
    width: 100%;
    height: 100%;
}

.all__upload__images button {
    cursor: pointer;
    padding: 0px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    color: white;
    border: none;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    right: -10px;
    top: -10px;
}

.pdfView__preview {
    margin: 0.5rem 10px;
    position: relative;
    padding: 8px;
    width: 243px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E8E8EA;
    border-radius: 4px;
    display: flex;
}

.pdfView__preview p {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    color: #262626;
}

.pdfView__preview>button {
    cursor: pointer;
    padding: 0px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    color: white;
    border: none;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    right: -10px;
    top: -10px;
}

.previeww__color {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #4C8BFF;
    margin-top: 4px;
    cursor: pointer;
}

.feedback_alert {
    background-color: #FFF1E6;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
}

.summury__box_outline {
    background: #fff;
    border: 1px solid #e8e8ea;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 25px 20px;
}

.box__appriciate {
    display: flex;
}

.box__top_appriciate {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: 'center';
}

.icon__appriciate {
    margin-right: 17px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.feedback__rating {
    display: block;
    padding: 12px;
    background: #FFFFFF;
    border: 1px solid #EBECF0;
    border-radius: 4px;
    margin-bottom: 20px;
}

.feedback__rating h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #172B4D;
    margin: 0;
    padding: 0;
}

.feedback__rating>p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #172B4D;
    word-break: break-all;
}

.box__top_appriciate h3 {
    padding: 0px;
    margin: 0px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
}

.box__top_appriciate p {
    padding: 0px;
    margin: 7px 0px 0px 0px;
    font-size: 15px;
    color: #615E83;
    font: 600;
}

.one__icons__bottom {
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.one__icons__bottom>p {
    margin-right: 20px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: #615E69;
}

.one__icons__bottom>p>span>svg {
    width: 14px;
    height: 14px;
    color: #615E69;
    margin-right: 10px;
}

.feedback__appreciation {
    text-align: center;
}

.feedback__appreciation>h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #172B4D;
}

.feedback__appreciation>p {
    word-break: break-all;
}
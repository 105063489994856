.employee_dashboard_heading {
    color: var(--Gray-600---Black, #172B4D);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 20px */
}




.employee_dashboard_card {
    left: 0px;
    top: 0px;
    border-radius: 4px;
    padding: 1.25rem;
    background: linear-gradient(255.86deg, rgba(76, 139, 255, 0.2) -89.77%, rgba(255, 255, 255, 0) -89.75%), #FFFFFF;
    border: 1px solid #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    height: 96px;
    cursor: pointer;

    svg:first-child {
        background: var(--bg);
        padding: 0.5rem;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
    }

}

.employee_dashboard_card::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 8px;
    height: 40px;
    width: 0.5%;
    background: var(--color);
}



.employee_dashboard_card:hover {
    transform: scale(0.98);
    box-shadow: 2px 1px 8px 0px rgb(0 0 0 / 11%);
    transition: 0.2s ease-in all;
}

.employee_dashboard_card__title {
    display: flex;
    align-items: center;
    margin: 0;
    color: #615E83;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;

    /* 128.571% */
    svg {
        margin-left: 0.5rem;
    }
}

.employee_dashboard_card__count {
    color: #172B4D;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 90% */
    margin-block: 0.8rem;

    span {
        color: #A5A3A9;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 0.2rem;
    }
}


.employee_dashboard_overview_card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    height: 320px;
    width: 100%;
    overflow-y: scroll;
    padding: 1rem;
    gap: 1rem;

    p {
        color: #172B4D;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-block: 0;
    }

    .employee_dashboard_overview_card_chart {
        height: 80%;
        width: 100%;
    }
}

.element-card {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    height: 400px;
    padding: 24px 32px 24px 32px;
}
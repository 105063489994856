.custom__table__container {
  width: 100%;
  height: 100%;
  position: relative;
  letter-spacing: 0.3px;
}

.custom__table__container::-webkit-scrollbar {
  visibility: hidden !important;
}

.custom__table__container::-webkit-scrollbar:hover {
  visibility: visible !important;
}

.custom__table__container>div>thead,
.custom__table__container>div>tbody {
  width: 100%;
  letter-spacing: 0.3px;
}

.custom__table__container>div>div {
  /* position: absolute; */
  /* top: 100%; */
  bottom: 0;
  width: 100%;
  box-shadow: none;
  height: 52px;
  background: transparent;
}

.custom__table__container>div>div>p {
  margin: 0px !important;
}

.custom__table__container>div>table>tbody>tr>td {
  border-bottom: 2px solid #F6F6F6 !important;
}

.last-sticky-divider {
  box-shadow: 2px 0px 1px -1px rgba(115, 115, 115, 0.2);
}

.sort-icon-container,
.sort-icon-container-active {
  margin-left: 4px;
  display: flex;
  align-items: center;
}

.sort-icon {
  height: 14px;
  width: 14px;
}

.sort-icon-container-active .sort-icon {
  color: blue;
}

.sort-icon-container-active:hover {
  cursor: pointer;
}

.sort-icon-container {
  visibility: hidden;
}

.header-content-container:hover .sort-icon-container {
  cursor: pointer;
  visibility: visible !important;
}

/* th {
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    padding: 2px 4px;
  } */
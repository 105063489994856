.reset__style,
.reset__style__disabled {
  width: 37px;
  height: 20px;
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #335aff;
  flex: none;
  order: 6;
  flex-grow: 0;
}

.reset__style__disabled {
  color: #c5c0c0;
  cursor: initial;
}

.container__styling {
  margin-top: 0;
  text-align: left;
  gap: 10px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}

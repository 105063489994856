.popover-list li:hover {
    color: #335aff;
    background-color: rgba(51, 90, 255, .05);
}

.popover-list li {
    padding-top: 0px;
}

.continus___feedback {
    width: 100%;
    margin-inline: auto;
    // margin-block-start: 1.5rem;
}

.feedback__tabs {
    background: #F3F3F3;
    border-radius: 4px;
    padding: 0px;
}

.feedback__tabs>button {
    padding: 7px 20px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #615E69;
    border-radius: 4px !important;
    margin: 2px;
    border: none !important;
}

.feedback__tabs>button:hover {
    background-color: #fff !important;
}

.continus___feedback .continuous__feedback__list {
    max-height: calc(100vh - 50vh);
    overflow: auto;
}

.continus___feedback .one__to__one__list {
    max-height: calc(100vh - 50vh);
    overflow: auto;
}

.continus___feedback .appreciations__list {
    max-height: calc(100vh - 50vh);
    overflow: auto;
}
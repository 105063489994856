.nav-item1{
    text-align: center;
    width: 100%;
    display: block;
}

.nav-item1-a{
    text-align: left;
    text-transform: capitalize;
    margin: 0px;
}
.searchboxinput:hover{
    border-color:grey;
}
.one{
    min-width: 300px;
    flex-grow : 0.7;
    max-width:300px;
    margin: 20px 10px 0 20px !important;
    height: calc(100vh - 220px);
    border-right: 1px solid #ebecf0;
    padding: 20px;

}

.two{
    border-right: 1px solid #ebecf0;
    padding: 20px;

}

.card-container{
    overflow: auto;
    max-height: 420px;
    margin-top: 10px;
}

.right-card-container{
    display: flex;
    justify-content: space-between;
}

.right-card-holder{
    margin:10px;
    overflow: auto;
    width: 100%;
    max-height: 420px;
}

.below-h4{
    padding-left: 15px;
}

.ant-card-body{
    padding: 10px !important;
}

.clicked-card{
    color: #335aff;
}

.not-clicked-card{
    color: black;
}

.nav-item-text{
    font-size: large;
}

.select-multi-select-create-s{
    max-width: 200px;
    flex-grow: 1;
    min-width: 150px;
}

.structure-detail-row{
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    border: 1px solid #e0e0e0;
    padding: 1rem !important;
    background-color: white;
}

@media (min-width: 992px){
.col-struc-detail{
    -webkit-flex: 0 0 25%;
}
}

@media  (min-width:768px) and (max-width:992px) {
        .col-struc-detail{
            -webkit-flex: 0 0 30%;
        }
    }

@media  (min-width:576px) and (max-width:768px) {
        .col-struc-detail{
            -webkit-flex: 0 0 40%;
        }
    }

@media only screen and (min-width:360px) and (max-width:576px) {
        .col-struc-detail{
            -webkit-flex: 0 0 50%;
        }
    }



.block-container-up-s{
    margin: 2rem;
    
}

.card-margin{
    margin-bottom: 8px;
}


.active{
    color: unset !important;
    border-bottom: unset !important;
}


.float-button{
    position:fixed; 
	bottom:60px;
	right:80px;
	text-align:center;
    width: 120px;
    border-radius: 5px;
    font-family: 'Poppins';
    height: 36px;
    /* top: -40px; */
}


.button-curve{
    margin: 10px;
    border-radius: 10%;
}

.red-approval-by{
    background-color : #cd5c5c;
}

.approve_actions{
    border:none;
     background:white;
     cursor:pointer;
}

.popover-list li:hover {
    color: #335aff;
    background-color: rgba(51, 90, 255, .05);
}

.popover-list li {
    padding-top: 0px;
}
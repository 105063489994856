.toggle-button-group {
  background: #f3f3f3;
  border-radius: 4px;
  padding: 0px;
}

.toggle-button-group > button {
  padding: 7px 20px !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #615e69;
  border-radius: 4px !important;
  margin: 2px;
  border: none !important;
}

/* .toggle-button-group > button:hover {
  background-color: #fff !important;
} */

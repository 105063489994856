.admin-searchInputs {
  /* margin-top: 105px; */
  display: flex;
  position: relative;
  height: 32px;
  width: 300px;
}

/* .admin-searchInputs input {
  background-color: whitesmoke;
  border:1px solid #d0d7ef;
  border-radius: 25px;
  font-size: 14px;
  padding:5px 15px;
  width:100%;
  text-align: center;
} */

.admin-searchInputs .clickable-text {
  background-color: whitesmoke;
  border: 1px solid #d0d7ef;
  border-radius: 25px;
  font-size: 14px;
  padding: 5px 15px;
  width: 100%;
  text-align: center;
  color: lightgray;
}

.admin-NavIcon,
.admin-searchIcon {
  height: 26px;
  width: 20px;
  background-color: whitesmoke;
  display: grid;
  place-items: center;
  z-index: 1;
  margin-top: 2px;
  position: absolute;
  cursor: pointer;
}

.admin-searchIcon {
  left: 10px;
  color: goldenrod;
}

.admin-NavIcon {
  right: 10px
}

.admin-searchIcon svg {
  font-size: 19px;
}

.admin-NavIcon svg {
  font-size: 19px;

}

input:focus {
  outline: none;
}


.admin-dataResult {
  position: absolute;
  margin-top: 5px;
  width: 50vw;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 6px;
}

.admin-dataResult::-webkit-scrollbar {
  display: none;
}

.admin-dataResult .admin-dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.admin-dataItem p {
  margin-left: 10px;
}

.admin-dataItem {
  text-decoration: none;
  cursor: pointer;
}

.admin-dataItem:hover {
  background-color: lightgrey;
}

#admin-clearBtn {
  cursor: pointer;
}
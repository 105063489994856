.no__data__found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}


.no__data__found>p {
    margin-block: 0.5rem;
    color: #A3A3A3;
}
.pdfViewer__modal {
    /* width: 90vh;
    height: 95vh; */
    border: 24px solid #292929;
    border-top: 0;
    margin-inline: auto;
    background-color: #ffffff;
    border-radius: 0.5rem;
}

.pdfViewer__header {
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #323639;
}

.pdfViewer__header>h2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    padding-inline-start: 0.5rem;
}

.pdfViewer__container {
    width: 100%;
    margin-inline: auto;
    margin-block: 4%;
    height: 100%;
}

.pdfViewer__actions {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: #515151;
    border-radius: 2px;
}

.rpv-default-layout__toolbar {
    padding: 0;
}

.rpv-default-layout__sidebar,
.rpv-default-layout__sidebar--ltr {
    display: none;
}

.rpv-default-layout__container {
    border: none;
}
.list_container {
  list-style: none;
  display: flex;
  gap: 0.75rem;
  overflow: auto;
  margin-bottom: 0 !important;
  padding-inline-start: 0 !important;
}

.list_item {
  display: flex;
}

.label_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 0.25rem;
  border: 0.4px solid #dedede;
  background: #fff;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.04);
  width: 3rem;
  min-height: 15.25rem;
  flex-shrink: 0;
  // margin-bottom: 10px;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
  overflow: hidden;
  transition: width 0.45s ease-in-out, background 0.45s ease-in-out;

  .label {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: #000;
    font-family: Segoe UI;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 0.875rem */
    text-transform: uppercase;
  }
  .selected {
    visibility: hidden;
  }
}

.label_container.selected {
  display: none;
}

.fixed_label_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .fixed_label {
    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 0.875rem */
    text-transform: uppercase;
    margin-right: 10px;
    margin-left: 10px;
    white-space: nowrap;
  }

  .not_selected {
    visibility: hidden;
  }
}

.accslide {
  display: block;
  // height: max-content;
  width: 0;
  overflow: hidden;
  color: #333;
  background: #fff;
  font: 12px/1.5 Helvetica, Verdana, sans-serif;
  transition: width 0.45s ease-in-out;
}

.accslide.open {
  width: 100%; /* Adjust to expand fully */
  // width:min-content;
}

.card {
  border-radius: 0.25rem;
  border: 0.4px solid #dedede;
  background: #fff;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.04);
  padding: 1rem;
  min-width: 400px;
}

.achievement_label {
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 550;
  line-height: 1.5rem; /* 0.875rem */
  text-transform: uppercase;
  white-space: nowrap;
}

.ordered_list {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 1rem;
}

.label_container:hover {
  cursor: pointer;
}

.custom_accord_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.select_label {
  position: absolute;
  left: 0.75rem;
  top: -0.5rem;
  background: #fff;
  color: #757575;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */
  letter-spacing: 0.025rem;
  z-index: 100;
}

.remark_card {
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);

  .remark_by_type {
    color: #757575;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
  }

  .emp_name {
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
  }

  .time_stamp {
    color: #afafaf;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
  }

  .remark_content {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: break-all;
  }
}

.details_card {
  border-radius: 0.375rem;
  border: 1px solid #f3f3f3;
  background: #fff;
  min-height: 10.625rem;
  padding: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  gap: 1.5rem;

  .detail_content {
    width: 100%;

    .emp_name_details {
      color: var(--Gray-600---Black, #172b4d);
      font-family: Segoe UI;
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 1.375rem */
    }

    .emp_code_details {
      color: #615e69;
      text-align: center;
      font-family: Segoe UI;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.875rem; /* 87.5% */
    }

    .detail_desg {
      color: #172b4d;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 1rem */
    }

    .detail_label {
      color: #a5a3a9;
      font-family: Segoe UI;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .detail_value {
      color: #615e69;
      font-family: Segoe UI;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.chip_style_common {
  display: inline-flex;
  padding: 0rem 0.75rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 1.25rem !important;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.raise_expense_hr {
  background: #e8e8ea;
  color: #e8e8ea;
  height: 0.2px;
  width: 100%;
}

.survey_question_container {
  padding: 24px;
  margin-top: 24px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ececec;

  .question_label {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
  .survey_options_container {
    padding: 4px 10px;
    margin: 5px 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 8px;
  }

  .survey_options_rating {
    cursor: pointer;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 8px;
    margin-inline-end: 10px;
  }

  .ratingBox {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 8px;
  }

  .ratingText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .label_14 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #172b4d;
  }
  .emoji_container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 8px;
  }
  .nps_container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.custom-select-container {
  border: 0;
  display: inline-flex;
  flex-direction: column;
  margin: 0;
  min-width: 0;
  padding: 0;
  position: relative;
  vertical-align: top;
}

.custom-select-label {
  background: rgb(255, 255, 255);
  display: block;
  font-weight: 400;
  color: rgb(51, 51, 51);
  font-size: 0.9rem;
  left: 0;
  letter-spacing: 0.00938em;
  line-height: 1.4375em;
  max-width: calc(133% - 32px);
  overflow: hidden;
  padding: 0;
  padding-inline: 5px;
  pointer-events: auto;
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  transform: translate(14px, -9px) scale(0.75);
  transform-origin: left top;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  white-space: nowrap;
  z-index: 1;
}

.custom-select-helper-text {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  margin-bottom: 0;
  margin-left: 4px;
  margin-right: 14px;
  margin-top: 4px;
  text-align: left;
}

.custom-select-fieldset {
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: inherit;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  inset: -5px 0 0;
  margin: 0;
  min-width: 0%;
  overflow: hidden;
  padding: 0 8px;
  pointer-events: none;
  position: absolute;
  text-align: left;
}

.custom-select-legend {
  display: block;
  float: unset;
  font-size: 0.75em;
  height: 11px;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  visibility: hidden;
  white-space: nowrap;
  width: auto;
}

$table-height: 650/8.15 +"vh";

@mixin overflowAuto() {
  overflow: auto;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0.5vh;
    height: 0.5vh;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: tranparent;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #bdbdbd;
    }
  }
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0.73vh;
  height: 0.73vh;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: tranparent;
}
:hover {
  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
  }
}

@mixin hideLastChild {
  &:last-child {
    display: none;
  }
}
@mixin visibleComponent {
  &:hover {
    visibility: visible !important;
  }
}
.MuiSvgIcon-root {
  color: currentColor;
  fill: currentColor;
}
.MuiStepper-root,
.MuiStepper-horizontal {
  .MuiStepLabel-horizontal {
    .MuiStepLabel-root,
    .MuiStepLabel-iconContainer {
      // padding: 0px !important;
      .MuiStepIcon-active {
        color: #4dcf69 !important;
        border: 2px solid #4dcf69;
        .MuiStepIcon-text {
          fill: #4dcf69 !important;
          font-size: 12px !important;
        }
      }
      .MuiStepIcon-root {
        fill: transparent;
        width: 18px !important;
        height: 18px !important;
        display: inline-block;
        border: 2px solid currentColor;
        border-radius: 50% !important;
        color: #979797;
      }
      .Mui-active {
        color: #4dcf69 !important;
      }
      .MuiStepIcon-completed {
        color: #4dcf69 !important;
        fill: currentColor !important;
        width: 20px !important;
        height: 20px !important;
        border: 0px !important;
      }

      .MuiStepIcon-text {
        fill: currentColor;
        display: flex;
        // font-family: "Roboto", sans-serif;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        &:active {
          fill: #4dcf69 !important;
        }
      }
    }

    .email-settings-select {
      padding-inline: 2rem;
      padding-block: 0.7rem;
      border: 0.5px solid lightgrey !important;
      border-radius: 3px;

      &:focus {
        border: 0.5px solid black !important;
        border-radius: 3px;
      }
    }

    .selectLabelStepper {
      margin: 0px 8px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      color: #4dcf69 !important;
    }
    .selectLabelStepper-inactive {
      margin: 0px 8px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      color: #979797 !important;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.top-70px {
  top: 70px !important;
}
.b-0 {
  border: 0px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-120 {
  margin-top: 120px !important;
}
.m-0 {
  margin: 0% !important;
}
.p-0 {
  padding: 0% !important;
}
.mp-0 {
  margin: 0px !important;
  padding: 0px !important;
}
.w-100 {
  width: 100% !important;
}
.w-95 {
  width: 95% !important;
}
.w-90 {
  width: 90% !important;
}
.w-50 {
  width: 50% !important;
}
.h-100 {
  height: 100% !important;
}
.h-90 {
  height: 90% !important;
}
.h-95 {
  height: 95% !important;
}
.h-inherit {
  height: inherit;
}
.h-100-vh {
  height: 100vh;
}
.wh-100 {
  height: 100% !important;
  width: 100% !important;
}
.display-flex {
  display: flex;
}
.start-flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-around-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-start-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.flex-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.wrap-flex {
  flex-wrap: wrap;
}
.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.column-gap-5 {
  column-gap: 0.5rem !important;
}
.column-gap-1 {
  column-gap: 1rem !important;
}
.column-gap-2 {
  column-gap: 2rem !important;
}
.flex-end-start {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-overlay {
  @include overflowAuto();
}
.visible-visible {
  @include visibleComponent();
}
.visible-hidden {
  visibility: initial;
  &:hover {
    visibility: inherit;
  }
}
.list-popover {
  width: 13.75rem;
  background-color: #ffffff;
  .list-item {
    width: 100%;
    height: 6.13vh;
    cursor: pointer;
    .list-item-icon {
      min-width: 2rem !important;
      color: inherit;
    }
    &:hover {
      background-color: rgba(51, 90, 255, 0.0506993);
      color: #335aff;
    }
  }
}

.locality-icon{
  min-width: 32px !important;
}

.slider {
  width: 100%;
  height: 100%;
  background-color: #03192e;
}
.slide-dot-container {
  height: 3px;
  width: 90px;
  .active {
    background: #2c50e8 !important;
    width: 34px !important;
    border: 1px solid #2c50e8 !important;
    height: 1px;
    border-radius: 100px;
  }
  .dot {
    height: 1px;
    background: #ffffff;
    width: 10px;
    border: 1px solid #ffffff;
    border-radius: 100px;
  }
}
.logo {
  height: 3.36vh;
}
.authLayOut {
  width: 100vw;
  height: calc(100vh - 60px);
}

.modal-container {
  width: 650px;
  height: auto;
  border-radius: 5px;
  background-color: #ffffff;
  overflow: hidden;
  .modal-component {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 3.68vh 3.68vh 1.84vh;
    .clearIcon {
      color: #172b4d;
      font-size: 18px;
    }
    .download-template-card {
      width: 100%;
      height: auto;
      padding: 2.94vh;
      border: 1px solid #ebecf0;
      border-radius: 6px;
      margin: 5.15vh 0px 3.68vh;
      .download-button {
        border: 1px solid #ebecf0;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 10px;
        color: #335aff;
        font-size: 14px !important;
        font-weight: 500;
      }
    }
    .import-file {
      border: 1px solid #ebecf0;
      border-radius: 6px;
      width: 100%;
      height: auto;
      padding: 2.94vh;
    }
    .dragger-file-area {
      margin-top: 2.94vh;
      width: 100%;
      height: 11.9vh;
      border: 2px dashed #d8d8d8;
      background-color: #f4f8fc;
      padding: 2.94vh 0px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .custom-button-modal {
    border-top: 1px solid #ebecf0;
    width: 100%;
    height: auto;
    padding: 1.84vh 3.68vh;
  }
  &:focus {
    outline: none !important;
  }
}

.welcomeModal {
  width: 796px;
  height: 540px;
  background-color: #ffffff;
  border-radius: 20px;
  .linearProgress {
    width: 18.4vh;
    height: 4px;
    border-radius: 100px;
  }
  &:focus {
    outline: none !important;
  }
}
.header-height {
  height: 90px;
}
.emp-stepper {
  width: 100vw;
  height: calc(100vh - 250px);
  .custom-stepper {
    padding: 24px 0px;
    width: 100vw;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
    .inside-custom-stepper {
      margin-left: 14vw;
      width: 40vw;
      min-width: 380px;
    }
  }
  .emp-create {
    height: 100% !important;
    .emp-create-from-list {
      @include overflowAuto();
      width: 72vw;
      padding: 8px 1vw 0px;
      .emp-create-from {
        flex-wrap: wrap;
        list-style: none;
        li:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
.drawer-open {
  width: 100vw;
  height: 90vh;
  background-color: #fff;
}
.border-top-radius-10 {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.drawerArea {
  width: 100px !important;
  height: 100vh;
  z-index: 0;

  .navigationArea {
    width: 150px;
    height: calc(100vh - 90px) !important;
    // border-right: 1px solid #ebecf0;
    .link-navigateList {
      width: 100%;
      text-decoration: none;
      display: flex;
      .navigationList {
        width: calc(100% - 24px);
        height: 60px;
        margin: 0px 5px !important;
        .listIcon {
          margin: 0px 20px 0px 8px;
          min-width: 24px !important;
        }
        .list-text-color {
          color: inherit !important;
        }
      }
      .collapse-button {
        width: calc(100% - 44px);
        margin: 0px 12px 0px 32px;
        height: auto;
      }
    }
  }
}
.app-card {
  cursor: pointer;
  width: 360px;
  height: auto;
  padding: 24px;
  margin-bottom: 20px;
  margin-right: 20px;
  .app-media {
    width: 46px;
    height: 46px;
    background-color: transparent;
    border-radius: 5px;
    margin-right: 14px;
  }
  .app-title {
    color: #172b4d;
    // font-family: "Archivo", sans-serif;
    font-weight: 600;
    font-size: 18px;
  }
  .app-content {
    color: #bdbdbd;
    // font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
}
.create-user-footer {
  width: 100vw;
  height: 100px;
  z-index: 2;
  background-color: #ffffff;
  .create-user-footer-inside {
    // border-top: 1px solid #f0f0f0;
    width: 72vw;
    height: 35px;
  }
}

.componayPageTitle {
  height: 12vh;
  width: 100%;
}
.companyPage-content {
  width: 100%;
  height: calc(100% - 12vh);
}
.divider-orgainsation {
  @include hideLastChild();
  height: 3.68vh;
  width: 22px;
}
.visible-divider {
  display: flex;
  &:last-child {
    display: none;
  }
}
.iconButton {
  width: 35px;
  height: 35px;
  background: #f8f8f8;
  border-radius: 3px;
  color: #172b4d;
  cursor: pointer;
}
.right-navigation {
  margin: 30px 40px;
  width: calc(100% - 80px);
  height: calc(100% - 60px);
  padding: 0px !important;
  .page_name {
    // font-family: "Archivo Black", sans-serif;
    font-size: 20px;
    color: #172b4d;
  }
  .create-button {
    width: 114px;
    background-color: #335aff;
    color: #ffffff !important;
    text-transform: "none";
    font-size: 14px;
    // font-family: "Roboto", sans-serif;
    font-weight: 500;
    cursor: pointer;
  }
}

.cardList {
  width: 90%;
  border: 1px solid #ebecf0;
  border-radius: 8px;
  cursor: pointer;
  .subcard {
    width: 100%;
    border-top: 1px solid #ebecf0;
    padding: 20px 0px;
    height: 32px;
    .selectedCard {
      height: 32px;
      min-width: 70px;
      padding: 0px 20px;
      background-color: #f8f8f8;
      border: 1px solid #ebecf0;
      border-radius: 3px !important;
    }
  }
}
.header {
  z-index: 2 !important;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  .header-logo {
    img {
      height: 36px;
      width: 101px;
    }
  }
  .header-user {
    font-size: 30px !important;
    color: #172b4d;
    cursor: pointer;
  }
  .header-image {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 15px;
  }
}
.filter-style {
  width: 19.4vw;
  background-color: #fcfcfc;
  height: 100%;
  .filter-title {
    color: #172b4d !important;
    // font-family: "Archivo", sans-serif;
    font-weight: 600;
    font-size: 18px;
    padding: 12px 10px;
    border-bottom: 1px solid #ebecf0;
    width: 100%;
  }

  .list {
    cursor: pointer;
    padding: 10px 14px;
    border-bottom: 1px solid #ebecf0;
    background-color: #fcfcfc !important;
    .MuiAccordionDetails-root {
      padding: 8px 0px 0px !important;
      background-color: #fcfcfc !important;
    }
    .accord-list {
      width: 100%;
      max-height: 15vh;
      overflow: auto !important;
    }
    .MuiAccordionSummary-root {
      height: 30px !important;
      min-height: 30px !important;
      padding: 0px !important;
      background-color: #fcfcfc !important;
    }
    .accord-title {
      height: 24px !important;
      h3 {
        color: #172b4d !important;
        // font-family: "Archivo", sans-serif;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}

.transfer-modal {
  width: 460px;
  height: 85.28vh;
  background: #ffffff;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
}
// table container

.fixed-table-admin {
  width: inherit;
  height: inherit;
  margin: 0px;
  padding: 0px;

  .MuiTablePagination-toolbar {
    padding: 0px !important;
    margin: 0px !important;
    .MuiIconButton-root {
      color: inherit;
      padding: 5px;
      &:disabled {
        color: #bdbdbd;
      }
    }
  }
  .table-container {
    @include overflowAuto();
    width: 100%;
    border: 1px solid #eee;
    .table-container-shadow {
      box-shadow: inset -3px 0px 6px -3px rgba(115, 115, 115, 0.2);
    }

    // border-bottom: 1px solid #f0f0f0;
    table {
      font-family: "Segoe UI Web (West European)";
      table-layout: fixed !important;
      .table-row {
        height: 60px !important;
      }
      td,
      th {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }
      .fixed-table-cell {
        width: 160px !important;
        // height: 60px !important;
      }
      .fixed-table-cell-height {
        height: 54px !important;
        // width: auto !important;
      }
      .fixed-column-header-admin {
        height: 40px;
      }
      .width-140px {
        width: 160px !important;
      }
      .p-10px {
        padding: 0px 10px;
      }
      .width-170px {
        width: 170px !important;
      }
      .fixed-table-header {
        width: 40px !important;
        height: 40px !important;
        color: #ebecf0;
      }
      .fixed-table-checkbox {
        width: 40px !important;
        height: 60px !important;
        color: #ebecf0;
      }
      .fixed-icon-cell {
        width: 40px !important;
        height: 40px !important;
        background-color: #fff;
        // box-shadow: -3px 0px 6px rgba(115, 115, 115, 0.2);
      }
      .h-60-px {
        height: 60px;
      }
      .fixed-column {
        position: sticky;
        *position: relative;
        background-color: #fff;
      }
      .last-cell-shadow {
        box-shadow: 2px 0px 1px -1px rgba(115, 115, 115, 0.2);
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.MuiFormControlLabel-labelPlacementStart {
  margin: 0px !important;
}
.search-outlined-component {
  width: 100%;
  .MuiAutocomplete-popupIndicatorOpen {
    transform: rotate(0deg);
  }
}
.MuiAutocomplete-endAdornment {
  top: auto !important;
}
.modalComponent {
  width: 460px;
  height: auto;
  border-radius: 5px;
  background-color: #fff;
  &:focus {
    outline: none;
  }
}

.disable-field {
  width: 100%;
  background: #f8f8f8;
  border-radius: 6px;
  height: 50px;
  padding: 17px;
}

.disable-field-auto {
  width: 100%;
  border-radius: 6px;
  padding: 7px;
  padding-top: 0 !important;
}

.file-card {
  background: #ffffff;
  border: 1px solid #ebecf0;
  box-sizing: border-box;
  height: 50px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.other-setting-card {
  width: 100%;
  border: 1px solid #ebecf0;
  border-radius: 6px;
}

.other__setting__card__row{
  width: 100%;
  border: 1px solid #ebecf0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-inline:1rem;
  padding-block:0.5rem;
  margin-block: 0.8rem;
  
}

.form_fields_parent {
  position: relative;
  margin-bottom: 50px;
}

.form_fields_parent .more-option-cancel {
  position: absolute;
  top: -17px;
  right: 5px;
}

.table-org-master {
  width: 100%;
  .tableHead {
    background-color: #f8f8f8;
  }
  .tableCell {
    border: 0.5px solid #ebecf0;
    min-width: 140px;
  }
}
.w-48 {
  width: 48%;
}
.search-component {
  width: 210px;
  height: 40px;
  background: #ffffff;
  border: 2px solid #ebecf0;
  border-radius: 3px;
  margin-right: 20px;
  padding: 12px;
  cursor: pointer;
  .icon {
    font-size: 18px;
    color: #172b4d;
    margin-top: 0px !important;
  }
}
.input-search {
  border: 0px !important;
  outline: none !important;
  width: inherit;
  &:focus {
    border: 0px !important;
    outline: none !important;
  }
}

.input-select {
  border: 0px !important;
  outline: none !important;
  width: 100%;
  &:focus {
    border: 0px !important;
    outline: none !important;
  }
  .option {
    height: 48px;
  }
}

.alert-dialog-modal {
  width: 505px;
  background: #ffffff;
  border-radius: 5px;
}

.bulk-edit-modal {
  background-color: #ffffff;
  border-radius: 5px;
  &:focus {
    outline: none !important;
  }
}

.user-form-container {
  width: 100vw;
  height: calc(100vh - 60px);
  .custom-stepper_1 {
    width: 100%;
    height: 60px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
    .inside-custom-stepper_1 {
      width: 60%;
      min-width: 380px;
    }
  }
  .emp-create_1 {
    height: calc(100% - 126px) !important;
    .emp-create-from-list_1 {
      @include overflowAuto();
      width: 100%;
      .emp-create-from_1 {
        flex-wrap: wrap;
        list-style: none;
        li:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .create-user-footer_1 {
    width: 100vw;
    height: 66px;
    z-index: 2;
    background-color: #ffffff;
    .button-container {
      width: 100%;
      height: 65px;
    }
  }
}
.button-container {
  width: 100%;
  height: 65px;
}
.width-50-10px {
  width: calc(50% - 10px);
}
.width-50-16px {
  width: calc(50% - 16px);
}
.width-25-10px {
  width: calc(25% - 10px);
}
.width-33-10px {
  width: calc(33% - 10px);
}
.more-option-cancel {
  top: -12px;
  left: calc(100% - 16px);
}

// company logo css start
.company_logo {
  width: 140px;
  height: 140px;
  background: #ffffff;
  border: 1px solid #ebecf0;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 30px;
  .edit_button {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 0px;
    position: absolute;
    top: 11px;
    right: 10px;
    color: #335aff;
    outline: none !important;
    cursor: pointer;
    display: inline-block;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    svg {
      font-size: 14px;
    }
  }
}
// company logo css end

// color-pallets css start
.color_pallets {
  border: 1px solid #ebecf0;
  width: 183px;
  padding: 11px 0px 6px 10px;
  box-sizing: border-box;
  border-radius: 4px;
  .color_item {
    border: 0;
    background: transparent;
    width: 30px;
    height: 30px;
    display: inline-block;
    padding: 0;
    outline: none !important;
    margin: 3px 5px;
    position: relative;
    padding: 2px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.5s ease;
    span {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      &.purple {
        background-color: #34004b;
      }
      &.golden {
        background-color: #e4d1c2;
      }
      &.red {
        background-color: #5b1b39;
      }
      &.dark {
        background-color: #172b4d;
      }
      &.light {
        background-color: "#FFFFFF";
      }
      &.green {
        background-color: #3b5352;
      }
      &.cyan {
        background-color: #01c6de;
      }
      &.peach {
        background-color: #e5b099;
      }
      &.pink {
        background-color: #e0bcbe;
      }
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #fff;
      font-size: 19px;
      opacity: 0;
    }
    &:hover {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    }
    &.active {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      svg {
        opacity: 1;
      }
    }
  }
}
// color-pallets css end

// fields_css start
.field_name {
  min-width: 145px;
  font-size: 14px !important;
  font-weight: 500;
  margin-bottom: 20px !important;
  color: #757575;
  margin-right: 50px;
}
// fields_css end

.modal-style {
  width: 460px;
  height: auto;
  outline: none;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
}

.modal-upper-area {
  width: 100%;
  height: calc(100% - 66px);
}
.modal-lower-area {
  width: 100%;
  height: 65px;
}
.modal-create-property {
  width: 460px;
  min-height: 68vh;
  height: auto;
  max-height: 75vh;
  border-radius: 5px;
  background-color: #fff;
  overflow: scroll;
  &:focus {
    outline: none;
  }
}
.input-employeeCode {
  border: 0px !important;
  outline: none !important;
  width: 100px;
  text-align: end;
  font-weight: 500;
  background-color: transparent;
  font-size: 0.825rem;
  // font-family: "Roboto", sans-serif;
  &:focus {
    border: 0px !important;
    outline: none !important;
  }
}

.tableHeight {
  height: 70vh;
}

.modalComponent-create {
  width: 460px;
  max-height: 80vh;
  border-radius: 5px;
  background-color: #fff;
  &:focus {
    outline: none;
  }
}

.list-popover-app {
  width: 250px;
  background-color: #ffffff;
  .list-item-app {
    width: 100%;
    cursor: pointer;
    .list-item-icon-app {
      min-width: 2rem !important;
      color: inherit;
    }
  }
}

.break-word {
  word-break: break-all;
}

.menu-style {
  background: #f8f8f8;
  border: 1px solid #ebecf0;
  border-radius: 3px;
  height: 26px;
  width: fit-content;
  padding: 0px 4px;
}

.openAccessModal {
  width: 460px;
  max-height: 655px;
  height: 80vh;
  background-color: #ffffff;
  border-radius: 5px;

  &:focus {
    outline: none !important;
  }
}

.app-popover {
  width: 220px;
  height: 122px;
}
.app-screen-grid {
  display: grid;
  //grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  grid-template-rows: 94px;
  .app-tab {
    display: grid;
    grid-template-columns: 48px auto;
    width: 100%;
    grid-template-rows: 46px;
    grid-column-gap: 14px;
    padding: 24px 20px;
    text-decoration: none;
    border: 1px solid #ebecf0;
    border-radius: 6px;
    height: 94px;
  }
}

.w-200px {
  width: 200px !important;
}
.w-400px {
  width: 400px !important;
}
.material-toolbar {
  z-index: 9;
  border-bottom-left-radius: 32px;
}
.imagecolor {
  width: 46px;
  height: 46px;
  border-radius: 5px;
  margin-right: 15px;
}
.list-item-app > .imagecolor:hover {
  background-color: transparent;
}
.scrollbar-opacity > div:nth-last-child(-n + 2) {
  opacity: 0;
}
.scrollbar-opacity:hover > div:last-child,
div:nth-last-child(2) {
  opacity: 1;
}
.company-details {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-template-rows: 80px 100px;
  grid-row-gap: 40px;
}

.font-app-icon {
  // font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
}
.header-color-0 {
  margin-bottom: 20px;
  text-decoration: none;
  border-radius: 0px;
  &:hover {
    background-color: rgba(51, 90, 255, 0.05);
  }
  &:last-child {
    margin-bottom: 0px;
  }
}
.header-color-1 {
  margin-bottom: 20px;
  text-decoration: none;
  border-radius: 0px;
  &:hover {
    background-color: rgba(0, 205, 55, 0.05);
  }
  &:last-child {
    margin-bottom: 0px;
  }
}
.header-color-2 {
  margin-bottom: 20px;
  text-decoration: none;
  border-radius: 0px;
  &:hover {
    background-color: rgba(250, 200, 0, 0.05);
  }
  &:last-child {
    margin-bottom: 0px;
  }
}
.header-color-3 {
  margin-bottom: 20px;
  text-decoration: none;
  border-radius: 0px;
  &:hover {
    background-color: rgba(221, 79, 36, 0.05);
  }
  &:last-child {
    margin-bottom: 0px;
  }
}

.field-name-form {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.width-85 {
  width: 85%;
}

.logout-btn {
  height: 39px;
  width: 50px;
  border-radius: 6px;
  margin-left: 25px;
  margin-top: 5px;
  cursor: pointer;
}
.logout-text {
  margin-left: 10px !important;
  font-weight: 500 !important;
}
.left-navigate-scroll > div {
  margin-right: -12px !important;
}
.modal-component-leave-rules {
  width: 461px;
  height: auto;
  background: #ffffff;
  border-radius: 5px;
  position: relative;
  &:focus {
    outline: none !important;
  }
}
.menu-style-access {
  background: #f8f8f8;
  border: 1px solid #ebecf0;
  border-radius: 3px;
  height: 26px;
  width: max-content;
  padding: 0px 4px;
}

.padding-access {
  padding: 5px !important;
}
.column-content td {
  padding: 15px !important;
}
.app-popover-access {
  width: 220px;
  height: 100%;
}

.delete-icon:hover{
  color:#E70000;
}

.action-icon{
  height: 1.25rem !important;
  width: 1.25rem !important;
  cursor: pointer;

}

.modal-icon-container{
  border-radius: 50%;
  background: rgba(76, 139, 255, 0.1);
  padding:4px;
  width:40px;
  height:40px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.add-margin-bottom {
  margin-bottom: 20px !important;
}

.g-10{
  gap: 10px;
}

.g-5{
  gap: 5px;
}